import {endpoints} from './endpoints'
import request from '../request'

export function login (email, pass) {
	return new Promise((resolve, reject) => {
		const data = {
			email: email,
			password: pass
		}
		request({
			url: endpoints.login,
			method: 'post',
			data
		})
			.then(async function (response) {
				setUserToken(response)
				await getUser()
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function register (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.register,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				console.log('error: ', error)
				reject(error)
			})
	})
}

function setUserToken (token) {
	localStorage.setItem('userToken', token.access_token)
}

export function getUser() {
	return new Promise((resolve) => {
		request({
			url: endpoints.userInfo,
			method: 'get'
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				resolve(error)
			})
	})
}

export function updateUser(data) {
	return new Promise((resolve) => {
		request({
			url: endpoints.updateUser,
			method: 'post',
			data
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				resolve(error)
			})
	})
}

export function resetPassRequest(data) {
	return new Promise((resolve) => {
		request({
			url: endpoints.resetPassRequest,
			method: 'post',
			data
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				resolve(error)
			})
	})
}

export function checkToken(data) {
	return new Promise((resolve) => {
		request({
			url: endpoints.checkToken,
			method: 'post',
			data
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				resolve(error)
			})
	})
}

export function resetPass(data) {
	return new Promise((resolve) => {
		request({
			url: endpoints.resetPass,
			method: 'post',
			data
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				resolve(error)
			})
	})
}

export function loginWithGoogleToken (access_token) {
	return new Promise((resolve, reject) => {
		const data = {access_token}
		
		request({
			url: endpoints.loginWithGoogleToken,
			method: 'post',
			data
		})
			.then(async function (response) {
				setUserToken(response)
				await getUser()
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}