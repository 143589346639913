import { newMessage } from "@/helpers/api/conversations"

const CRMStore = {
  namespaced: true,
  state: {
		loadingConversation: false,
		currentConversationID: null,
    conversation: null,
    conversation_messages: [],
		showChatBox: false
  },
  mutations: {
    setConversation(state, conversation) {
      state.conversation = conversation
			state.conversation_messages = conversation.messages
    },
    setConversationMessages(state, conversation_messages) {
			state.conversation_messages = conversation_messages
    },
    setLoadingConversation(state, status) {
      state.loadingConversation = status
    },
    setShowChatBox(state, status) {
      state.showChatBox = status
    }
  },
  actions: {
    async newMessage({ state, commit }, message) {
      commit('setLoadingConversation', true)
			const data = {message}
			if (state.conversation) { data.conversation_id = state.conversation.id }
      const new_message = await newMessage(data)
      if (new_message.code === 200) {
				const conversation = {
					message_data: new_message.message_data,
					...new_message.conversation
				}
        commit('setConversation', conversation)
      }
      commit('setLoadingConversation', false)
    }
  }
}

export default CRMStore
