<template>
  <div class="text-center">
    <v-dialog
      v-model="$store.state.users.newUserModal"
      max-width="800"
      scrollable
    >
      <v-card>
        <v-card-text class="pa-0">
          <div class="py-4 px-8">
            <div class="d-flex flex-row align-center justify-space-between">
              <div class="d-flex flex-row align-center">
                <v-icon color="white" small class="info-icon mr-2">mdi-account</v-icon>
                <p class="mb-0 font-weight-bold text-primary title">Usuario</p>
              </div>
              <v-btn class="mx-2" fab dark small color="#F4516C" @click="closeModal">
                <v-icon dark>mdi-close</v-icon>
              </v-btn>
            </div>
            <v-form ref="form" v-model="validform" class="mt-6">
              <v-row class="mb-4">
                <v-col cols="12" md="2" class="py-0">
									<v-avatar
										size="80"
										class="profile-avatar"
										@click="triggerFileInput"
									>
										<img :src="avatarUrl" class="user-avatar" v-if="avatarUrl"/>
										<v-icon v-else class="user-avatar icon" color="#524D4D" x-large>mdi-account</v-icon>
										<div class="logo-text">Modificar Imagen</div>
									</v-avatar>
                  <v-file-input
										ref="fileInput"
                    v-model="newUserData.avatar"
                    show-size
                    outlined
                    dense
                    accept=".png,.jpg,.jpeg"
                    placeholder="Seleccione imagen de perfil"
                    prepend-inner-icon="mdi-image"
                    prepend-icon=""
										style="display: none;"
										@change="onFileSelected"
                  ></v-file-input>
								</v-col>
								<v-col cols="12" md="5" class="py-0">
                  <v-text-field
                    v-model="newUserData.name"
                    label="Nombre"
                    placeholder="Ingrese nombre del usuario"
                    outlined
                    required
                    dense
                    :rules="requiredRule"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="5" class="py-0">
                  <v-text-field
                    v-model="newUserData.lastname"
                    label="Apellido"
                    placeholder="Ingrese apellido del usuario"
                    outlined
                    required
                    dense
                    :rules="requiredRule"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="py-0">
                  <v-text-field
                    v-model="newUserData.rut"
                    label="Rut"
                    placeholder="Ingrese el rut del usuario"
                    outlined
                    required
                    dense
										hint="Ingresa rut sin puntos ni guión"
                    :rules="rutRule"
										@blur="onBlurRut"
										@focus="onFocusRut"
										maxlength="9"
                  ></v-text-field>
                  <v-text-field
                    v-model="newUserData.email"
                    label="Email"
                    placeholder="Ingrese email del usuario"
                    outlined
                    required
                    dense
                    :rules="emailRules"
                  ></v-text-field>
                  <v-text-field
										v-if="!newUserData.user_id"
                    v-model="newUserData.password"
                    label="Contraseña"
                    placeholder="Ingrese contraseña del usuario"
                    outlined
                    required
                    dense
                    :rules="requiredRule"
                  ></v-text-field>
                  <v-text-field
                    v-model="newUserData.phone"
                    label="Teléfono"
                    placeholder="Ingrese teléfono del usuario (Ej: 56912345678)"
                    outlined
                    required
                    dense
                    :rules="phoneRule"
										maxlength="11"
										@keypress="isNumberKey"
                  ></v-text-field>
                  <v-text-field
                    v-model="newUserData.address"
                    label="Dirección"
                    placeholder="Ingrese dirección del usuario"
                    outlined
                    required
                    dense
                    :rules="requiredRule"
                  ></v-text-field>
                </v-col>
							</v-row>

              <v-row class="mt-4">
								<v-col cols="5" class="py-0">
									<div class="d-flex align-center justify-space-between">
										<h2 class="mb-0 subtitle-1 font-weight-bold">Rol en Empresa</h2>
										<v-btn
											dark
											x-small
											outlined
											color="#524D4D"
											@click="addRole"
										>
											<p class="mb-0 mr-1" style="font-size: 10px">Crear Rol</p>
											<v-icon dark x-small>mdi-plus</v-icon>
										</v-btn>
									</div>
									<div class="d-flex flex-row">
										<v-select
											v-model="newUserData.role_id"
											:items="$store.state.companies.roles"
											:loading="$store.state.companies.loadingRoles"
											item-text="name"
											item-value="id"
											label="Seleccione Rol en Empresa"
											single-line
											outlined
											dense
											required
											:rules="requiredRule"
										></v-select>
									</div>
								</v-col>
							</v-row>

              <v-row align="center">
                <v-col cols="12">
                  <div class="d-flex flex-row justify-end">
                    <v-btn
                      class="mx-2 my-1"
                      color="white"
                      raised
                      @click="closeModal"
                    >
                      <p class="mb-0 text-primary">Cancelar</p>
                    </v-btn>
                    <v-btn
                      class="mx-2 my-1"
                      color="#524D4D"
                      raised
                      dark
                      :loading="loadingData"
                      @click="sendForm"
                    >
                      <p class="mb-0">Confirmar</p>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {createUserForCompany, updateUserForCompany} from '@/helpers/api/company'
import {validate, format, clean} from '@/helpers/rut.js'

export default {
  name: 'NewUserModal',
  components: { },
  data: () => ({
    validform: true,
    newUserData: {
			user_id: null,
      email: null,
      name: null,
      lastname: null,
			rut: null,
      phone: null,
      address: null,
      role_id: 1,
      avatar: null,
      company_id: null,
			password: null
    },
		userCreated: null,
    acceptRejectModal: false,
    acceptRejectModalData: null,
    loadingData: false,
		avatarUrl: null,
		rutRule: [
			v => validate(v) || 'Rut no válido'
		],
    requiredRule: [
      v => !!v || 'Campo requerido'
    ],
    phoneRule: [
			v => !!v || 'Campo requerido',
			v => /^569\d{8}$/.test(v) || 'Debe tener 11 dígitos y empezar con 569',
    ],
    emailRules: [
      v => !!v || 'Email es obligatorio',
      v => /.+@.+\..+/.test(v) || 'Email debe ser válido',
    ],
  }),
  computed: {
		selectedCompany(){
			return this.$store.state.companies.selectedCompany
		}
  },
  mounted(){
		if(this.$store.state.users.newUserModalEditing){
			this.newUserData = {
				...this.$store.state.users.newUserModalEditing,
				...this.$store.state.users.newUserModalEditing.profile,
				...this.$store.state.users.newUserModalEditing.company_rol
			}
			this.avatarUrl = this.$store.state.users.newUserModalEditing.profile.avatar
		}
  },
  methods: {
		isNumberKey(event) {
			const charCode = event.which ? event.which : event.keyCode;
			if (charCode < 48 || charCode > 57) {
				event.preventDefault();
			}
		},
    addRole() {
			this.$store.commit('companies/setNewRoleModalEditing', null)
			this.$store.commit('companies/setNewRoleModal', true)
    },
		onBlurRut(){
			if(validate(this.newUserData.rut)){
				this.newUserData.rut = format(this.newUserData.rut)
			}
		},
		onFocusRut(){
			if(validate(this.newUserData.rut)){
				this.newUserData.rut = clean(this.newUserData.rut)
			}
		},
    triggerFileInput() {
      this.$refs.fileInput.$el.querySelector('input[type="file"]').click();
    },
    onFileSelected(file) {
      if (file) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.avatarUrl = e.target.result
        };
        reader.readAsDataURL(file)
      }
    },
    closeModal(){
      this.$store.commit('users/setNewUserModal', false)
      this.$store.commit('users/setNewUserModalEditing', null)
      this.newUserData = {
				user_id: null,
				email: null,
				name: null,
				lastname: null,
				rut: null,
				phone: null,
				address: null,
				role_id: 1,
				avatar: null,
				company_id: null,
				password: null
      }
      this.validform = true
      this.loadingData = false
    },
    async sendForm(){
      if(this.$refs.form.validate()){
        this.loadingData = true
        const formData = new FormData()
        let newUserData = {...this.newUserData, ...{password_confirmation: this.newUserData.password }}
				newUserData.rut = clean(newUserData.rut)
        Object.keys(newUserData).forEach((key) => {
          if(newUserData[key]) {
            formData.append(key, newUserData[key])
          }
        })
        const user = this.newUserData.user_id ? await updateUserForCompany(formData):await createUserForCompany(formData)
        if(user.code === 200){
					this.userCreated = user.user
          const messageData = {
            message: this.newUserData.user_id ? 'El usuario ha sido actualizado correctamente':'Se ha creado correctamente el usuario',
            title: this.newUserData.user_id ? 'Usuario actualizado':'Usuario creado',
            type: 'success',
            icon: 'mdi-check-circle',
            color: '#3EBFA3',
          }
					this.$store.dispatch('users/getUserFromCompany')
          this.setMessage(messageData)
          this.closeModal()
        }
        else if(user.data){
					this.userCreated = user.user
          user.data.forEach(message => {
            const messageData = {
              message,
              title: 'Error',
              type: 'danger',
              icon: 'mdi-close-octagon',
              color: '#F4516C',
            }
            this.setMessage(messageData)
          })
        }
        this.loadingData = false
      }
    },
    setMessage(messageData){
      const data = {
        message: messageData.message,
        title: messageData.title,
        created: new Date(),
        type: messageData.type,
        icon: messageData.icon,
        color: messageData.color,
      }
      this.$store.dispatch('general/addMessage', data)
    },
  }
};
</script>

<style scoped>
	.profile-avatar{
		cursor: pointer;
		position: relative;
	}
	.logo-text{
		position: absolute;
		bottom: 0;
		background-color: rgba(0, 0, 0, 0.7);
		width: 100%;
		color: white;
		font-size: 12px;
		padding-bottom: 10px;
		padding-top: 3px;
	}
  .info-container{
    color: #606060;
  }
  .text-primary{
    color: #524D4D;
  }
  .info-icon{
    background-color: #524D4D;
    border-radius: 25px;
    padding: 3px;
    width: 35px;
    height: 35px;
  }
  .info-icon.small-icon{
    width: 25px;
    height: 25px;
  }
  .info-img{
    width: 100%;
    max-height: 50px;
    object-fit: contain;
  }
  .adjuntos-container{
    background-color: #EFEFEF;
    max-height: 95px;
    overflow-y: scroll;
  }
  .interaction-container{
    width: 100%;
    background-color: #E5E9F2;
    border-radius: 5px;
    position: relative;
    padding: 12px;
  }
  .user-avatar.small{
    width: 25px;
    height: 25px;
  }
  .light-blue-text{color: #959595;}
  .interaction-container .icon-arrow{
    font-size: 80px;
    position: absolute;
    top: 20px;
    line-height: inherit;
  }
  .interaction-container.right .icon-arrow{
    left: -46px;
  }
  .interaction-container.left .icon-arrow{
    right: -46px;
  }
  /deep/ .custom-input-numbers .v-input__slot{padding:0!important;}
  /deep/ .custom-input-numbers .v-input__prepend-inner,
  /deep/ .custom-input-numbers .v-input__append-inner,
  /deep/ .custom-text-input .v-input__append-inner{margin-top:0!important;}
  /deep/ .custom-input-numbers input {text-align: center }
</style>