<template>
	<div>
    <v-data-table
      :headers="headers"
      :items="client_crms"
      item-key="id"
      :loading="loadingList"
      loading-text="Cargando datos..."
    >
      <template v-slot:item.date="{ item }">
				<b>{{ item.created_at | toDateTime }}</b>
      </template>
      <template v-slot:item.actions="{ item }">
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							class="mx-1"
							icon
							dark
							x-small
							color="primary"
							@click="openCrmDetail(item.id)"
							v-bind="attrs"
							v-on="on"
						>
							<v-icon small dark>mdi-eye</v-icon>
						</v-btn>
					</template>
					<span>Ver Detalle</span>
				</v-tooltip>
      </template>
    </v-data-table>
	</div>
</template>

<script>
import {getClientCRM} from '@/helpers/api/clients'
export default {
	name: 'ClientsCrmList',
	props: {
		clientId: {
      type: Number,
      required: true
    },
		openCrmDetail: {
      type: Function,
      required: true
    }
	},
	data: () => ({
		loadingList: true,
		client_crms: [],
		headers: [
        { text: 'ID CRM', value: 'id' },
        { text: 'Fecha de Creación', value: 'date' },
        { text: 'Acciones', value: 'actions', sortable: false },
      ],
	}),
	mounted(){
		this.getClientCRM()
	},
	methods: {
		async getClientCRM(){
			this.loadingList = true
			try {
				const client_crm = await getClientCRM({
					client_id: this.clientId,
					start: 1,
					limit: 100
				})
				if(client_crm.code == 200){
					this.client_crms = client_crm.client_crm
				}
			} catch (error) {
				console.log(error)
			}
			this.loadingList = false
		}
	}
}
</script>

<style>

</style>