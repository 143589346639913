const endpoints = {
	confirmSchedule: '/schedule/confirmSchedule',
	closeSchedule: '/schedule/closeSchedule',
	getScheduleGeo: '/schedule/getScheduleGeo',
	getUserSchedule: '/schedule/getUserSchedule',
	sendReportSchedule: '/schedule/sendReportSchedule',
	updateScheduleAddress: '/schedule/updateScheduleAddress',
	getUserSchedules: '/schedules/getUserSchedule',
	createUserSchedule: '/schedules/createUserSchedule',
	updateUserSchedule: '/schedules/updateUserSchedule',
	deleteUserSchedule: '/schedules/deleteUserSchedule',
	sendSingleEmailForSchedule: '/schedules/sendSingleEmailForSchedule',
	redirectToGoogle: '/googlecalendar/redirectToGoogle',
	getEvents: '/googlecalendar/getEvents',
	getHolidays: '/googlecalendar/getHolidays'
}
export {endpoints}