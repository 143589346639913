<template>
	<div>
		<div v-if="$route.name == 'Companies'">
			<v-btn
				depressed
				color="#FF120B"
				small
				dark
				@click="$store.commit('companies/setNewCompanyModal', true)"
			>
				<v-icon small>mdi-plus</v-icon>
				<p class="mb-0 ml-2 caption">Nueva Empresa</p>
			</v-btn>
		</div>
		<div v-if="$route.name == 'Roles'">
			<v-btn
				depressed
				color="#FF120B"
				small
				dark
				v-if="$checkPermissions(['create_company_roles'])"
				@click="addRole"
			>
				<v-icon small>mdi-plus</v-icon>
				<p class="mb-0 ml-2 caption">Nuevo Rol Empresa</p>
			</v-btn>
		</div>
		<div v-if="$route.name == 'CompanyConcepts'">
			<v-btn
				depressed
				color="#FF120B"
				small
				dark
				v-if="$checkPermissions(['create_company_concepts'])"
				@click="$store.commit('companies/setNewCompanyConceptModal', true)"
			>
				<v-icon small>mdi-plus</v-icon>
				<p class="mb-0 ml-2 caption">Nuevo Concepto</p>
			</v-btn>
		</div>
		<div v-if="$route.name == 'ProjectTypes'">
			<v-btn
				depressed
				color="#FF120B"
				small
				dark
				v-if="$checkPermissions(['create_company_project_types'])"
				@click="$store.commit('companies/setNewProjectTypeModal', true)"
			>
				<v-icon small>mdi-plus</v-icon>
				<p class="mb-0 ml-2 caption">Nuevo Tipo de Proyecto</p>
			</v-btn>
		</div>
		<div v-if="$route.name == 'Users'">
			<v-btn
				depressed
				color="#FF120B"
				small
				dark
				v-if="$checkPermissions(['create_users'])"
				@click="addUser"
			>
				<v-icon small>mdi-plus</v-icon>
				<p class="mb-0 ml-2 caption">Agregar Usuario a Empresa</p>
			</v-btn>
		</div>
		<div v-if="$route.name == 'CRM'">
			<v-btn
				depressed
				color="#FF120B"
				small
				dark
				v-if="$checkPermissions(['edit_crm'])"
				@click="$store.commit('crm/setNewContactModal', true)"
			>
				<v-icon small>mdi-plus</v-icon>
				<p class="mb-0 ml-2 caption">Nuevo Contacto</p>
			</v-btn>

			<v-menu offset-y :close-on-content-click="false">
				<template v-slot:activator="{ on, attrs }">
				<v-btn
					depressed
					color="#524D4D"
					x-small
					dark
					fab
					class="ml-2 custom-size-button"
					v-bind="attrs"
					v-on="on"
				>
					<v-icon small>mdi-cog</v-icon>
				</v-btn>
				</template>
				<div class="pa-2 white">
					<p class="mb-0 caption font-weight-bold">Selecciona las columnas a visualizar</p>
					<v-checkbox v-model="header_columns.id" color="black" label="ID" hide-details dense @change="changeColumns"></v-checkbox>
					<v-checkbox v-model="header_columns.user" color="black" label="Nombre" hide-details dense @change="changeColumns"></v-checkbox>
					<v-checkbox v-model="header_columns.address" color="black" label="Dirección" hide-details dense @change="changeColumns"></v-checkbox>
					<v-checkbox v-model="header_columns.project_detail" color="black" label="Proyecto" hide-details dense @change="changeColumns"></v-checkbox>
					<v-checkbox v-model="header_columns.details" color="black" label="Tipo Proyecto" hide-details dense @change="changeColumns"></v-checkbox>
					<!-- <v-checkbox v-model="header_columns.message" color="black" label="Último Mensaje" hide-details dense @change="changeColumns"></v-checkbox> -->
					<v-checkbox v-model="header_columns.schedule" color="black" label="Estado Agenda VT" hide-details dense @change="changeColumns"></v-checkbox>
					<v-checkbox v-model="header_columns.schedule_user" color="black" label="Usuario Responsable VT" hide-details dense @change="changeColumns"></v-checkbox>
					<!-- <v-checkbox v-model="header_columns.type" color="black" label="Tipo de Agendamiento" hide-details dense @change="changeColumns"></v-checkbox> -->
					<v-checkbox v-model="header_columns.created_at" color="black" label="Fecha Creación" hide-details dense @change="changeColumns"></v-checkbox>
					<v-checkbox v-model="header_columns.actions" color="black" label="Acciones" hide-details dense @change="changeColumns"></v-checkbox>
				</div>
			</v-menu>
			<v-tooltip bottom>
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						depressed
						:color="$store.state.companies.userCompanyConnectedStatus?.status == 1 ? 'success':'error'"
						x-small
						:dark="!$store.state.companies.loadingUserCompanyConnectedStatus"
						fab
						class="ml-2 custom-size-button"
						:disabled="$store.state.companies.loadingUserCompanyConnectedStatus"
						:loading="$store.state.companies.loadingUserCompanyConnectedStatus"
						v-bind="attrs"
						v-on="on"
						@click="toogleConnectedStatus()"
					>
						<v-icon small>{{$store.state.companies.userCompanyConnectedStatus?.status == 1 ? 'mdi-account-badge-outline':'mdi-account-cancel-outline'}}</v-icon>
					</v-btn>
				</template>
				<span>{{$store.state.companies.userCompanyConnectedStatus?.status == 1 ? 'Chat: Disponible':'Chat: No Disponible'}}</span>
			</v-tooltip>
		</div>
		<div v-if="$route.name == 'Schedules'">
			<v-btn
				depressed
				color="#FF120B"
				small
				dark
				v-if="$checkPermissions(['schedule_access'])"
				@click="$store.commit('schedule/setNewScheduleModal', true)"
			>
				<v-icon small>mdi-plus</v-icon>
				<p class="mb-0 ml-2 caption">Nueva Agenda</p>
			</v-btn>
			<v-btn
				depressed
				color="#959595"
				small
				dark
				:class="$vuetify.breakpoint.mobile ? '':'ml-3'"
				v-if="$store.state.schedule.userGoogleCalendar"
				@click="syncCalendarAgenda"
			>
				<v-icon small>mdi-google</v-icon>
				<p class="mb-0 ml-2 caption">Sincronizar Google Calendar</p>
			</v-btn>
			<v-btn
				depressed
				color="#959595"
				small
				dark
				:class="$vuetify.breakpoint.mobile ? '':'ml-3'"
				v-else
				@click="toAsociateCalendar"
			>
				<v-icon small>mdi-google</v-icon>
				<p class="mb-0 ml-2 caption">Asociar Google Calendar</p>
			</v-btn>
		</div>
	</div>
</template>

<script>
import {redirectToGoogle, getEvents} from '@/helpers/api/schedule'

export default {
	name: 'ActionButtons',
	data: () => ({
		header_columns: {
			id: true,
			user: true,
			project_detail: true,
			address: true,
			// message: true,
			schedule: true,
			schedule_user: true,
			details: true,
			created_at: true,
			actions: true,
		}
	}),
	mounted(){
		this.header_columns = this.$store.state.crm.listingColumns
	},
	methods: {
		toogleConnectedStatus(){
			const data = {
				status: this.$store.state.companies.userCompanyConnectedStatus?.status == 2 ? 1 : 2
			}
			this.$store.dispatch('companies/updateUserCompanyConnectedStatus', data)
		},
		changeColumns(){
			this.$store.commit('crm/setListingColumns', this.header_columns)
		},
    addUser() {
			this.$store.commit('users/setNewUserModalEditing', null)
			this.$store.commit('users/setNewUserModal', true)
    },
    addRole() {
			this.$store.commit('companies/setNewRoleModalEditing', null)
			this.$store.commit('companies/setNewRoleModal', true)
    },
		async syncCalendarAgenda(){
			const events = await getEvents()
			if(events.code == 200){
				this.$store.dispatch('schedule/getUserSchedule')
				const messageData = {
					message: 'Sincronización realizada correctamente',
					title: 'Sincronización Completa',
					type: 'success',
					icon: 'mdi-check-circle',
					color: '#3EBFA3',
				}
				this.setMessage(messageData)
			}
			else{
				const messageData = {
					message: 'Hubo un error en la sincronización por favor intenta nuevamente',
					title: 'Error',
					type: 'danger',
					icon: 'mdi-close-octagon',
					color: '#F4516C',
				}
				this.setMessage(messageData)
			}
		},
		async toAsociateCalendar(){
			const redirect = await redirectToGoogle()
			if(redirect.code == 200){
				window.location.href = redirect.url
			}
		}
	}
};
</script>
<style scoped>
.custom-size-button.v-btn--fab.v-size--x-small{
	width: 28px;
	height: 28px;
}
</style>