import { getConversations, getConversation } from "@/helpers/api/conversations"
import router from '@/router'
const listingColumns = localStorage.getItem('crm_columns')

const CRMStore = {
  namespaced: true,
  state: {
		loadingConversations: false,
    conversations: [],
		conversation: null,
		loadingConversation: false,
		newContactModal: false,
		listingColumns: listingColumns ? JSON.parse(listingColumns):{
			id: true,
			user: true,
			address: true,
			// message: true,
			schedule: true,
			schedule_user: true,
			details: true,
			created_at: true,
			actions: true,
		}
  },
  mutations: {
    setConversations(state, conversations) {
      state.conversations = conversations
    },
    setLoadingConversations(state, status) {
      state.loadingConversations = status
    },
    setConversation(state, conversation) {
      state.conversation = conversation
    },
    setLoadingConversation(state, status) {
      state.loadingConversation = status
    },
    setNewContactModal(state, status) {
      state.newContactModal = status
    },
    setListingColumns(state, listingColumns) {
      state.listingColumns = listingColumns
			localStorage.setItem('crm_columns', JSON.stringify(listingColumns))
    }
  },
  actions: {
    async getConversations({ commit }) {
      commit('setLoadingConversations', true)
      const data = {
        start: 0,
        limit: 50
      }
      const conversations = await getConversations(data)
      if (conversations.code === 200) {
        commit('setConversations', conversations.conversations)
      }
      commit('setLoadingConversations', false)
    },
    getConversation({ commit }, conversation_id) {
      commit('setLoadingConversation', true)
			commit('setConversation', null)
      const data = {
        conversation_id
      }
      getConversation(data)
				.then((conversation) => {
					if (conversation.code === 200) {
						commit('setConversation', conversation.conversation)
					}
					commit('setLoadingConversation', false)
				})
				.catch(() => {
					commit('setConversation', null)
					commit('setLoadingConversation', false)
					router.push({name: 'CRM'})
				})
    }
  }
}

export default CRMStore
