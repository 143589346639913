<template>
  <v-container fluid>
		<div class="d-flex align-center justify-end">
			<div class="d-flex align-center" style="max-width: 500px;min-width: 300px;">
				<v-icon color="#524D4D" @click="getPolicies">mdi-magnify</v-icon>
				<v-text-field
					v-model="search"
					:loading="$store.state.general.loadingPolicies"
					outlined
					dense
					hide-details
					item-text="name"
					item-value="id"
					return-object
					clearable
					label="Búsqueda (Por correo del usuario)"
					class="mb-2 mr-2 custom-height-search custom-height-calendar"
					color="#524D4D"
					@input="sendToSearch"
				></v-text-field>
			</div>
		</div>
    <v-data-table
      :headers="headers"
      :items="$store.state.general.policiesLists"
      item-key="id"
			disable-sort
      :loading="$store.state.general.loadingPolicies"
      loading-text="Cargando datos..."
    >
      <template v-slot:item.user="{ item }">
				<div v-if="item.user && item.user.profile">
					<p class="mb-0 font-weight-bold">
						{{item.user.profile.name }} {{item.user.profile.lastname }}
					</p>
					<p class="mb-0 caption">
						{{item.user.email }}
					</p>
				</div>
				<p class="mb-0" v-else>
					Sin datos de usuario
				</p>
			</template>
      <template v-slot:item.date="{ item }">
				<p class="mb-0">
					{{item.created_at | toDate}}
				</p>
			</template>
      <template v-slot:item.companies="{ item }">
				<v-chip
					v-for="(company,index) in item.user_companies"
					:key="index"
					color=""
					label
					outlined
					x-small
					class="mx-1"
				>
					{{ company.company.name }}
				</v-chip>
			</template>
      <template v-slot:item.date="{ item }">
				<p class="mb-0">
					{{item.created_at | toDate}}
				</p>
			</template>
			<template v-slot:item.actions="{ item }">
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							class="mx-1"
							icon
							dark
							x-small
							color="primary"
							@click="$router.push({ name: 'UserDetailPolicies', params: { user_id: item.user_id } })"
							v-bind="attrs"
							v-on="on"
						>
							<v-icon small dark>mdi-eye</v-icon>
						</v-btn>
					</template>
					<span>Ver Detalle</span>
				</v-tooltip>
			</template>
    </v-data-table>
  </v-container>
</template>

<script>
import _debounce from 'lodash/debounce'
export default {
  data() {
    return {
      search: null,
			limit: 10,
			start: 0,
      headers: [
        { text: 'Usuario', value: 'user' },
        { text: 'Motivo', value: 'reason' },
        { text: 'Empresas Asociadas', value: 'companies', width: '25%' },
        { text: 'Fecha Aceptación', value: 'date' },
        { text: 'Acciones', value: 'actions' }
      ],
    };
  },
	mounted(){
		this.getPolicies()
	},
  methods: {
		sendToSearch: _debounce(function() { this.getPolicies() }, 800),
		getPolicies(){
			this.$store.dispatch('general/getPolicies', {start: this.start, limit: this.limit, search: this.search})
		}
  },
};
</script>

<style scoped>
</style>