<template>
	<v-container fluid pa-0 style="background-color: #F0F2F8;position: relative;" fill-height class="d-flex flex-column align-start">
		<Header v-if="$store.state.user.isAuthenticated"/>
		<Sidebar v-if="$store.state.user.isAuthenticated" />
		<RouterContainer
			v-if="!$store.state.user.loadingCompanyPermissions"
			:style="'height:'+$store.getters['general/availableHeight']+'px'"/>
		<Modals v-if="$store.state.user.isAuthenticated"/>
		<ChatBot />
		<MessageCenter  />
	</v-container>
</template>

<script>
import RouterContainer from './RouterContainer'
import Sidebar from './Sidebar'
import Modals from '@/components/Modals'
import MessageCenter from '@/components/Helpers/MessageCenter'
import ChatBot from '@/components/Helpers/ChatBot'
import Header from './Header'

export default {
	name: 'MainLayout',
	components: { RouterContainer, Sidebar, Modals, MessageCenter, ChatBot, Header },
	data: () => ({}),
};
</script>
