import {endpoints} from './endpoints'
import request from '../request'

export function createPreProject (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.createPreProject,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getCompanyPreProjects (params) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getCompanyPreProjects,
			method: 'get',
			params
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getPreProject (params) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getPreProject,
			method: 'get',
			params
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function addFileToPreProject (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.addFileToPreProject,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function addPreProjectBudgetsPart (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.addPreProjectBudgetsPart,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function removePreProjectBudgetsPart (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.removePreProjectBudgetsPart,
			method: 'delete',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}