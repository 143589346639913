<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="3">
        <v-card color="#959595" dark>
          <v-card-text class="py-2">
						<div>
							<p class="font-weight-bold body-2 mb-0">Usuarios Alcanzados</p>
							<p class="mb-0">Última semana</p>
						</div>
						<v-divider class="my-1"></v-divider>
						<div>
							<p class="font-weight-bold mt-4 text-right" style="font-size: 30px">150</p>
						</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="3">
        <v-card color="#524D4D" dark>
          <v-card-text class="py-2">
						<div>
							<p class="font-weight-bold body-2 mb-0">Pagos recibidos ($)</p>
							<p class="mb-0">Última semana</p>
						</div>
						<v-divider class="my-1"></v-divider>
						<div>
							<p class="font-weight-bold mt-4 text-right" style="font-size: 30px">$1.000.000</p>
						</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="3">
        <v-card color="#FF120B" dark>
          <v-card-text class="py-2">
						<div>
							<p class="font-weight-bold body-2 mb-0">Contratos En Desarrollo</p>
							<p class="mb-0">General</p>
						</div>
						<v-divider class="my-1"></v-divider>
						<div>
							<p class="font-weight-bold mt-4 text-right" style="font-size: 30px">12</p>
						</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="3">
        <v-card color="#dcdbdb">
          <v-card-text class="py-2">
						<div>
							<p class="font-weight-bold body-2 mb-0" style="color: #6d6d6d">Contratos Terminados</p>
							<p class="mb-0" style="color: #6d6d6d">General</p>
						</div>
						<v-divider class="my-1"></v-divider>
						<div>
							<p class="font-weight-bold mt-4 text-right" style="color: #6d6d6d;font-size: 30px">12</p>
						</div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mb-4">
      <v-col cols="12" md="6">
        <v-card color="white" outlined min-height="400" class="d-flex flex-column">
					<v-card-text class="flex-grow-1 d-flex flex-column justify-center">
						<div>
							<p class="font-weight-bold body-2 mb-0">Comparativa de prospectos recibidos</p>
							<p class="mb-0 caption">Últimos 7 meses</p>
						</div>
						<v-divider class="mt-1 mb-5"></v-divider>
						<div class="chart-container">
							<LineChart :chart-data="lineChartData" :chart-options="chartOptions" style="height: 300px" />
						</div>
					</v-card-text>
				</v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card color="white" outlined min-height="100" class="d-flex flex-column mb-2">
					<v-card-text class="flex-grow-1 d-flex flex-column justify-center">
						<div>
							<p class="font-weight-bold body-2 mb-0">Mapa de visitas técnicas (Confirmadas)</p>
							<p class="mb-0 caption">General</p>
						</div>
						<v-divider class="mt-1 mb-5"></v-divider>
						<GmapMap
							:center="{lat: -33.3807616, lng: -70.647808}"
							:zoom="9"
							style="width: 100%; height: 300px"
							:options="{
								mapTypeControl: false,
								fullscreenControl: true,
							}"
						>
							<GmapMarker
								v-for="(geo, index) in geoSchedules"
								:key="index"
								:position="geoSchedule(geo)"
								:clickable="true"
								:icon="{url: require('@/assets/marker_schedule.png'), scaledSize: { width: 45, height: 47 }}"
								@click="toConversation(geo)"
							/>
						</GmapMap>
					</v-card-text>
				</v-card>
        <v-card color="white" outlined min-height="100" class="d-flex flex-column mb-2">
					<v-card-text class="flex-grow-1 d-flex flex-column justify-center">
						<div>
							<p class="font-weight-bold body-2 mb-0">Clientes</p>
							<p class="mb-0 caption">Última semana</p>
						</div>
						<v-divider class="mt-1 mb-5"></v-divider>
							<Pie :chart-data="chartDataPie" :chart-options="chartOptionsPie" style="max-height: 100px"  />
					</v-card-text>
				</v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import 'chart.js/auto';
import { Line, Pie } from 'vue-chartjs';

export default {
  components: {LineChart: Line, Pie},
	computed: {
		loadingGeoSchedules(){
			return this.$store.state.dashboard.loadingScheduleGeo
		},
		geoSchedules(){
			return this.$store.state.dashboard.scheduleGeo
		}
	},
  data() {
    return {
      lineChartData: {
        labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio'],
        datasets: [
          {
            label: 'Ventas',
            backgroundColor: '#959595',
            borderColor: '#959595',
            data: [40, 39, 18, 40, 39, 80, 67],
            fill: false,
          },
          {
            label: 'Ventas',
            backgroundColor: '#524D4D',
            borderColor: '#959595',
            data: [3, 24, 8, 23, 25, 70, 61],
            fill: true,
          },
        ],
      },
      chartOptions: {
				responsive: true,
				maintainAspectRatio: false,
        scales: {
          x: {
            grid: {
              display: false,
              drawBorder: true,
            },
            ticks: {
              display: true,
							color: 'rgba(0, 0, 0, 0.6)'
            },
          },
          y: {
            grid: {
              display: false,
              drawBorder: true,
            },
            ticks: {
              display: true,
							color: 'rgba(0, 0, 0, 0.6)'
            },
          },
        },
        plugins: {
          legend: {
            display: false, // Muestra la leyenda si es necesario
          },
        },
      },
      chartDataPie: {
        labels: ['Alcanzados', 'Visitas Realizadas', 'Perdidos'],
        datasets: [
          {
            label: 'Votes',
            backgroundColor: ['#959595', '#524D4D', '#FF120B'],
            data: [12, 19, 3]
          }
        ]
      },
      chartOptionsPie: {
				responsive: true,
				maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'right',
            labels: {
              color: 'rgba(0, 0, 0, 0.6)',
              font: {
                size: 10
              }
            }
          },
          tooltip: {
            callbacks: {
              label: function(tooltipItem) {
                return tooltipItem.label + ': ' + tooltipItem.raw;
              }
            }
          }
        }
      }
    };
  },
	mounted(){
		this.getGeoSchedule()
	},
	methods: {
		toConversation(schedule){
			this.$router.push({ name: 'Conversation', params: { id: schedule.bot_conversation_id } })
		},
		getGeoSchedule(){
			this.$store.dispatch('dashboard/getScheduleGeo')
		},
		geoSchedule(schedule){
			if(schedule && schedule.project_info && schedule.project_info.coordinates){
				return {lat:schedule.project_info.coordinates.coordinates[1], lng:schedule.project_info.coordinates.coordinates[0]}
			}
			return null;
		}
	},
};
</script>

<style scoped>
.chart-container {
	flex: 1;
  width: 100%;
  height: 100%;
}

.chart-container canvas {
  width: 100% !important; /* Asegura que el canvas del gráfico ocupe todo el ancho disponible */
  height: 100% !important; /* Asegura que el canvas del gráfico ocupe toda la altura disponible */
}
</style>
