<template>
  <v-container fluid class="crm-conversation">
    <v-row class="mb-4" v-if="$store.state.preprojects.loadingPreProject">
      <v-col cols="12">
				<div class="d-flex flex-column justify-center align-center">
					<v-progress-circular
						:size="50"
						:width="5"
						color="rgba(0,0,0,0.2)"
						class="mb-3"
						indeterminate
					></v-progress-circular>
					Cargando Información
				</div>
      </v-col>
    </v-row>
		<v-row class="mb-4" v-else-if="preproject">
      <v-col cols="12" md="5">
        <v-card class="mb-4">
          <v-card-text class="py-2">
						<div class="d-flex align-center justify-space-between">
							<div class="d-flex align-center">
								<p class="font-weight-bold body-2 mb-0 mr-2">Datos Generales</p>
							</div>
						</div>
						<v-divider class="my-2"></v-divider>
						<div class="px-2 py-0">
							<div class="d-flex justify-space-between mb-1"><div style="width: 150px">ID Pre Proyecto</div><span>:</span> <b class="text-end" style="flex: 1;">{{preproject.id}}</b></div>
							<div class="d-flex justify-space-between align-center mb-1">
								<div class="d-flex justify-space-between" style="width: 100%"><div style="width: 150px">ID CRM Asociado</div><span>:</span> <b class="text-end" style="flex: 1;">{{preproject.info.crm_id}}</b></div>
								<v-btn
									icon
									color="warning"
									x-small
									class="ml-2"
									@click="toCRM(preproject.info.crm_id)"
								>
									<v-icon>mdi-eye</v-icon>
								</v-btn>
							</div>
							<div class="d-flex justify-space-between mb-1"><div style="width: 150px">Fecha de creación</div><span>:</span> <b class="text-end" style="flex: 1;">{{preproject.created_at | toDateTime}} hrs.</b></div>
							<div class="d-flex justify-space-between align-center">
								<p class="d-flex mb-0 justify-space-between" style="width: 100%"><span>Nombre Pre Proyecto:</span> <b class="ml-1">{{preproject.name}}</b></p>
							</div>
						</div>
						<v-divider class="my-1"></v-divider>
						<div class="d-flex align-center justify-space-between">
							<div class="d-flex align-center">
								<p class="font-weight-bold body-2 mb-0 mr-2">Datos Usuario</p>
							</div>
						</div>
						<v-divider class="my-1"></v-divider>
						<div class="pa-2">
							<div class="d-flex justify-space-between mb-1"><div style="width: 150px">Nombre</div><span>:</span> <b class="text-end" style="flex: 1;">{{preproject.client.profile?.name}}</b></div>
							<div class="d-flex justify-space-between mb-1"><div style="width: 150px">Email</div><span>:</span> <b class="text-end" style="flex: 1;">{{preproject.client.email}}</b></div>
							<div class="d-flex justify-space-between mb-1"><div style="width: 150px">Teléfono</div><span>:</span> <b class="text-end" style="flex: 1;">{{preproject.client.profile?.phone}}</b></div>
						</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="7">
        <v-card class="mb-4">
          <v-card-text class="py-2">
						<div class="d-flex align-center justify-space-between">
							<div class="d-flex align-center">
								<p class="font-weight-bold body-2 mb-0 mr-2">Carga de Archivos</p>
							</div>
						</div>
						<v-divider class="my-2"></v-divider>
						<v-row>
							<v-col cols="6" v-for="( file, index ) in preproject.files" :key="index">
								<div class="single-file-container pa-3 rounded">
									<p class="mb-0 caption">Descripción: <b>{{file.name}}</b></p>
									<p class="mb-0 caption">Fecha de carga: <b>{{file.created_at | toDateTime }}</b></p>
									<v-btn
										depressed
										color="#524D4D"
										small
										outlined
										class="mt-1"
										@click="downloadFile(file)"
									>
										<v-icon small>mdi-download</v-icon>
										<p class="mb-0 ml-2 caption">Descargar Archivo</p>
									</v-btn>
								</div>
							</v-col>
						</v-row>
						<v-divider class="my-2"></v-divider>
						<v-row class="mb-4">
							<v-col cols="6" v-for="( file, index ) in filesToUpload" :key="index">
								<div class="single-file-container pa-3 rounded">
									<p class="mb-2 caption">Archivo: <b>{{file.file.name}}</b></p>
                  <v-text-field
                    v-model="file.name"
                    label="Descripción"
                    outlined
                    required
                    dense
                    :rules="requiredRule"
                  ></v-text-field>
									<v-btn
										depressed
										color="#524D4D"
										small
										outlined
										:loading="file.loading"
										@click="uploadFile(file, index)"
									>
										<v-icon small>mdi-upload</v-icon>
										<p class="mb-0 ml-2 caption">Subir Archivo</p>
									</v-btn>
								</div>
							</v-col>
						</v-row>
						<div class="px-2 py-0">
							<v-btn
								depressed
								color="#524D4D"
								small
								outlined
								:loading="loading"
								@click="showFilePicker"
							>
								<v-icon small>mdi-file-document-plus-outline</v-icon>
								<p class="mb-0 ml-2 caption">Agregar Archivo</p>
							</v-btn>
							<input 
								type="file" 
								ref="fileInput"
								style="display: none;"
							/>
						</div>
          </v-card-text>
        </v-card>
        <v-card class="mb-4">
          <v-card-text class="py-2">
						<div class="d-flex align-center justify-space-between">
							<div class="d-flex align-center">
								<p class="font-weight-bold body-2 mb-0 mr-2">Presupuesto</p>
							</div>
						</div>
						<v-divider class="my-2"></v-divider>
						<div>
							<div v-if="preproject.budget">
								<div class="single-file-container my-1 pa-3 rounded d-flex align-center justify-space-between" v-for="( budget_part, index ) in preproject.budget.parts" :key="index">
									<div>
										<p class="mb-0 caption">Concepto del item: <b>{{budget_part.concept}}</b></p>
										<p class="mb-0 caption">Monto del item: <b>${{budget_part.value | toThousandFilter}}</b></p>
									</div>
									<v-tooltip bottom>
										<template v-slot:activator="{ on, attrs }">
											<v-btn
												icon
												dark
												x-small
												color="error"
												@click="deleteBugdetPart(budget_part)"
												v-bind="attrs"
												v-on="on"
											>
												<v-icon small dark>mdi-delete-outline</v-icon>
											</v-btn>
										</template>
										<span>Eliminar Item</span>
									</v-tooltip>
								</div>
								<div class="single-file-container my-1 pa-3 rounded">
									<p class="mb-0">Presupuesto Total: <b>${{preproject.budget.total_value | toThousandFilter}}</b></p>
								</div>
							</div>
						</div>
						<v-divider class="my-2"></v-divider>
						<v-row class="mb-4" v-if="showNewBudgetPart">
							<v-col cols="12">
								<div class="single-file-container pa-3 rounded">
									<p>Nuevo item de Presupuesto</p>
                  <v-text-field
                    v-model="newBudgetPart.value"
                    label="Monto del item"
                    outlined
                    required
                    dense
										@keypress="isNumberKey"
                    :rules="requiredRule"
                  ></v-text-field>
                  <v-text-field
                    v-model="newBudgetPart.concept"
                    label="Concepto del item"
                    outlined
                    required
                    dense
                    :rules="requiredRule"
                  ></v-text-field>
									<v-btn
										depressed
										color="#524D4D"
										small
										outlined
										@click="addNewPreProjectBudgetsPart"
									>
										<v-icon small>mdi-plus</v-icon>
										<p class="mb-0 ml-2 caption">Agregar al presupuesto</p>
									</v-btn>
								</div>
							</v-col>
						</v-row>
						<div class="px-2 py-0">
							<v-btn
								depressed
								color="#524D4D"
								small
								outlined
								class="mt-1"
								@click="showNewBudgetPartStart"
							>
								<v-icon small>mdi-currency-usd</v-icon>
								<p class="mb-0 ml-2 caption">Agregar Item de Presupuesto</p>
							</v-btn>
						</div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment'
import { gmapApi } from 'vue2-google-maps'
import {addFileToPreProject, addPreProjectBudgetsPart, removePreProjectBudgetsPart} from '@/helpers/api/preprojects'

export default {
	name: 'PreProject',
  data() {
    return {
			showNewBudgetPart: false,
			newBudgetPart: {
				value: null,
				concept: null,
				preproject_id: null
			},
			filesToUpload: [],
			mapZoom: 7,
      zoomInterval: null,
      isZooming: false,
			itemsAddress: [],
			loadingAddress: false,
			search: '',
			autocompleteService: null,
			tab: null,
			items: ['Información', 'Conversación', 'Correos Enviados', 'Informes'],
			reportTemplate: null,
			loading: false,
			editValue: false,
			editProjectDetail: false,
			editScheduleTitle: false,
			editUser: false,
			editAddress: false,
			newValueCrm: null,
			newScheduleTitle: null,
			newProjectDetail: null,
			loadingEditUser: false,
			reportEditor: '',
			menuTemplates: false,
			cc_report: null,
			scheduledUserId: null,
			changingScheduleUser: false,
			subject: 'Informe Visita Técnica',
			report_title: 'Informe Visita Técnica Revisión Normativa',
			activePanel: 2,
			templateName: null,
			userData: {
				name: null,
				email: null,
				phone: null
			},
			cachingItems: false,
			newAddress: {address: null, coordinates: null},
			requiredRule: [
				v => !!v || 'Campo requerido'
			],
			phoneRule: [
				v => !!v || 'Campo requerido',
				v => /^569\d{8}$/.test(v) || 'Debe tener 11 dígitos y empezar con 569',
			],
    };
  },
  computed: {
		google: gmapApi,
		editPermission(){
			return this.$checkPermissions(['edit_crm']) || this.scheduledUserId == this.$store.state.user.userInfo.id
		},
		scheduledUserName(){
			const user = this.$store.state.users.usersList.find((user) => {
				return user.id == this.scheduledUserId
			})
			if(!user){ return null }
			return user.profile.name + ' ' + user.profile.lastname
		},
		loadingUsers(){
			return this.$store.state.users.loadingUserList || this.changingScheduleUser
		},
		vtStatusText(){
			if(this.conversation.schedule.closure.length > 0){
				return 'Realizada'
			}
			else if(this.conversation.schedule.status == 1){
				return 'Esperando confirmación/pago'
			}
			return 'Confirmada'
		},
    preproject() {
      return this.$store.state.preprojects.preProject
    },
    reportTemplates() {
      return this.$store.state.vt.vtReportTemplates
    },
		conversationMessages(){
      return this.$store.state.crm.conversation.messages
		},
		geoAddress(){
			if(this.conversation && this.conversation.project_info && this.conversation.project_info.coordinates){
				return {lat: this.conversation.project_info.coordinates.coordinates[1], lng: this.conversation.project_info.coordinates.coordinates[0]}
			}
			return null
		},
		sentEmailDiff(){
			if(this.conversation.schedule && this.conversation.schedule.request_email.length > 0){
				const current = moment()
				const startTime = this.conversation.schedule.request_email[0].created_at
				var duration = moment.duration(current.diff(startTime))
				var hours = duration.asHours()
				return hours
			}
			else if(this.conversation.schedule.request_email.length == 0){
				return -1
			}
			return null
		},
		vtStatus(){
			if(this.conversation.schedule && this.conversation.schedule.status == 2 && this.conversation.schedule.request_email.length > 0){
				return {
					text: 'Informe Enviado',
					color: 'success'
				}
			}
			else if(this.conversation.schedule){
				return {
					text: 'Informe Pendiente',
					color: 'warning'
				}
			}
			return null
		}
  },
	watch: {
    search(newSearch) {
			this.cachingItems = false
      if (newSearch) {
        this.googleSearch(newSearch);
      } else {
        this.itemsAddress = []; // Limpiar los resultados si no hay búsqueda
      }
    },
		activePanel(newValue){
			if(newValue >= 0){
				this.$store.dispatch('vt/getVtReportTemplates')
			}
		},
		reportTemplates(newValue){
			if(newValue && this.reportTemplate){
				const reportSelected = newValue.find((report) => {
					return this.reportTemplate.id == report.id
				})
				if(reportSelected){
					this.onChangeReportTemplate(reportSelected)
				}
			}
		}
	},
	mounted(){
		try {
			this.$store.dispatch('users/getUserFromCompany')
			this.$store.dispatch('preprojects/getPreProject', this.$route.params.id)
			this.$nextTick(() => {
				this.$gmapApiPromiseLazy().then(() => {
					this.autocompleteService = new this.google.maps.places.AutocompleteService()
				})
			});
		} catch (error) {
			console.log('Error: ', error)
		}
	},
	beforeDestroy(){
		this.$store.commit('crm/setConversation', null)
	},
  methods: {
		showNewBudgetPartStart(){
			this.newBudgetPart = {
				value: null,
				concept: null,
				preproject_id: this.$route.params.id
			}
			this.showNewBudgetPart = true
		},
		isNumberKey(event) {
			const charCode = event.which ? event.which : event.keyCode;
			if (charCode < 48 || charCode > 57) {
				event.preventDefault();
			}
		},
    startZoomIn() {
      this.isZooming = true;
      this.zoomIn();
    },
    stopZoomIn() {
      this.isZooming = false;
      if (this.zoomInterval) {
        clearInterval(this.zoomInterval);
        this.zoomInterval = null;
      }
    },
    zoomIn() {
      if (this.zoomInterval) {
        clearInterval(this.zoomInterval);
      }

      this.zoomInterval = setInterval(() => {
        if (this.isZooming && this.mapZoom < 20) {
          this.mapZoom += 1;
					
					const map = this.$refs.vtMap.$mapObject;
					if (map) {
						map.setCenter(this.geoAddress); // El mapa se vuelve a centrar en el mismo punto
					}
        } else {
          clearInterval(this.zoomInterval);
          this.zoomInterval = null;
        }
      }, 100);
    },
    updateZoom(zoom) {
      this.mapZoom = zoom;
    },
    googleSearch(query) {
      const options = {
        input: query,
				componentRestrictions: { country: 'CL' }
      };
      this.autocompleteService.getPlacePredictions(options, (predictions, status) => {
        if (status === this.google.maps.places.PlacesServiceStatus.OK) {
          this.itemsAddress = predictions.map(prediction => ({
            text: prediction.description,
            value: prediction 
          }));
        } else {
          console.error('Error fetching predictions: ', status);
          this.itemsAddress = []; // Limpiar los resultados en caso de error
        }
      });
    },
		async getPlaceDetails(placeId) {
			if (typeof this.google !== 'undefined' && this.google.maps) {
				const service = new this.google.maps.places.PlacesService(document.createElement('div'));
				
				service.getDetails({ placeId }, (place, status) => {
					if (status === this.google.maps.places.PlacesServiceStatus.OK) {
						const { geometry } = place;
						this.newAddress.coordinates = {
							lat: geometry.location.lat(),
							lng: geometry.location.lng()
						}
						this.updateAddress(this.newAddress)
					}
				});
			} else {
				console.error('Google Maps API no está cargada');
			}
		},
    change(value) {
			console.log('value', value)
			this.getPlaceDetails(value.value.place_id)
      this.newAddress.address = value.text;
    },
		startCloseAgenda(){
			if(this.conversation.schedule){
				this.$store.commit('vt/setAgendaClosureModal', this.conversation.schedule.id)
			}
		},
		async editReportTemplate(reportTemplate, duplcating = false){
			const rt = {...reportTemplate}
			if(duplcating){
				rt.name = rt.name + ' (Copia)'
				delete rt.id
			}
			this.$store.commit('vt/setNewReportTemplateEditing', rt)
      this.$store.commit('vt/setNewReportTemplateModal', true)
		},
		cleanReportEditor(){
			this.reportEditor = ''
		},
		onChangeReportTemplate(report_template){
			this.report_title = report_template.name
			this.reportEditor = report_template.content
		},
		showFilePicker() {
			const input = this.$refs.fileInput;
			input.onchange = async (event) => {
				const file = event.target.files[0]
				this.filesToUpload.push({
					preproject_id: this.$route.params.id,
					file,
					name: null,
					loading: false
				})
			};
			input.click();
		},
		async uploadFile(file, index){
			file.loading = true
			const formData = new FormData()
			formData.append('file', file.file)
			formData.append('preproject_id', file.preproject_id)
			formData.append('name', file.name)
			await addFileToPreProject(formData)
			this.$store.dispatch('preprojects/getPreProject', this.$route.params.id)
			this.filesToUpload.splice(index, 1)
			file.loading = false
		},
		downloadFile(file){
			window.open(file.file_link, '_blank')
		},
		toCRM(crm_id) {
			this.$router.push({ name: 'Conversation', params: { id: crm_id } })
		},
		async addNewPreProjectBudgetsPart(){
			const data = {...this.newBudgetPart}
			this.newBudgetPart = {
				value: null,
				concept: null,
				preproject_id: this.$route.params.id
			}
			this.showNewBudgetPart = false
			await addPreProjectBudgetsPart(data)
			this.$store.dispatch('preprojects/getPreProject', this.$route.params.id)
		},
		async deleteBugdetPart(budget_part){
			await removePreProjectBudgetsPart({budget_part_id: budget_part.id})
			this.$store.dispatch('preprojects/getPreProject', this.$route.params.id)
		}
  },
};
</script>

<style scoped>
	.messages-container{
		display: flex;
		flex-direction: column;
		padding: 10px;
		flex: 1;
		border-radius: 10px;
		height: 400px;
		overflow-y: scroll;
	}
	.single-message{
		background-color: rgb(217, 239, 217);
		border: 1px solid rgb(197, 232, 197);
		padding: 4px 8px;
		max-width: 80%;
		border-radius: 10px;
		font-size: 14px;
		margin-bottom: 8px;
		text-transform: none;
	}
	.single-message.assistant{
		background-color: rgb(213, 211, 211);
		border: 1px solid rgb(193, 191, 191);
		align-self: flex-end;
	}
	.template-list-item:hover{
		background-color: rgba(0,0,0,0.2);
	}
	.menu-bg-white{background: white;}
	.single-file-container{
		background-color: white;
		border: 1px solid rgb(193, 191, 191);
	}
</style>
<style>
	.crm-conversation .single-message p{
		margin-bottom: 0;
	}
</style>