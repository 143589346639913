import { getCompanyPreProjects, getPreProject } from "@/helpers/api/preprojects"
import router from '@/router'

const PreProjectsStore = {
  namespaced: true,
  state: {
		loadingPreProjects: false,
    preProjects: [],
		preProject: null,
		loadingPreProject: false,
		newPreProjectModal: false
  },
  mutations: {
    setPreProjects(state, preProjects) {
      state.preProjects = preProjects
    },
    setLoadingPreProjects(state, status) {
      state.loadingPreProjects = status
    },
    setPreProject(state, preProject) {
      state.preProject = preProject
    },
    setLoadingPreProject(state, status) {
      state.loadingPreProject = status
    },
    setNewPreProjectModal(state, status) {
      state.newPreProjectModal = status
    }
  },
  actions: {
    async getPreProjects({ commit }) {
      commit('setLoadingPreProjects', true)
      const data = {
        start: 0,
        limit: 20
      }
      const preProjects = await getCompanyPreProjects(data)
      if (preProjects.code === 200) {
        commit('setPreProjects', preProjects.preprojects)
      }
      commit('setLoadingPreProjects', false)
    },
    getPreProject({ commit }, preproject_id) {
      commit('setLoadingPreProject', true)
			commit('setPreProject', null)
      const data = {
        preproject_id
      }
      getPreProject(data)
				.then((preproject) => {
					if (preproject.code === 200) {
						commit('setPreProject', preproject.preproject)
					}
					commit('setLoadingPreProject', false)
				})
				.catch(() => {
					commit('setPreProject', null)
					commit('setLoadingPreProject', false)
					router.push({name: 'PreProjects'})
				})
    }
  }
}

export default PreProjectsStore
