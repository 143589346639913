<template>
  <v-container fluid>
    <v-row class="mb-4">
      <v-col>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          solo
        ></v-text-field>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="$store.state.users.companyClients"
      :search="search"
      item-key="id"
      :loading="$store.state.users.loadingCompanyClients"
      loading-text="Cargando datos..."
    >
      <template v-slot:item.name="{ item }">
				<div class="d-flex align-center">
					<v-avatar
						size="30"
					>
						<img :src="item.profile?.avatar" class="user-avatar" v-if="item.profile?.avatar"/>
						<v-icon v-else class="user-avatar icon" color="#524D4D" x-large>mdi-account</v-icon>
					</v-avatar>
					<p class="ml-2 mb-0">{{ item.profile?.name }}</p>
				</div>
      </template>
      <template v-slot:item.actions="{ item }">
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							fab
							dark
							x-small
							color="#959595"
							class="mx-1"
							@click="toClientDetail(item)"

							v-bind="attrs"
							v-on="on"
						>
							<v-icon small dark>mdi-clipboard-account-outline</v-icon>
						</v-btn>
					</template>
					<span>Ver ficha cliente</span>
				</v-tooltip>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      search: '',
      headers: [
        { text: 'Nombre', value: 'name' },
        { text: 'Email', value: 'email' },
        { text: 'Acciones', value: 'actions', sortable: false },
      ],
    };
  },
  computed: {
    filteredUsers() {
      return this.$store.state.users.usersList.filter(user => {
        return (
          user.name.toLowerCase().includes(this.search.toLowerCase()) ||
          user.email.toLowerCase().includes(this.search.toLowerCase())
        );
      });
    },
		selectedCompany(){
			return this.$store.state.companies.selectedCompany
		}
  },
	watch: {
		selectedCompany(newValue){
			if(newValue){
				this.$store.dispatch('users/getCompanyClients')
			}
		}
	},
	mounted(){
		if(this.selectedCompany){
			this.$store.dispatch('users/getCompanyClients')
		}
	},
  methods: {
		toClientDetail(item){
			console.log('toClientDetail: ', item)
			this.$router.push({ name: 'Client', params: { id: item.id } })
		}
  },
};
</script>

<style scoped>
</style>