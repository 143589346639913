import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './stores/index'
import router from './router'
import filters from './filters/filters'
import * as VueGoogleMaps from 'vue2-google-maps'
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import 'tiptap-vuetify/dist/main.css'
import '@/assets/scss/main.scss'
import { checkPermissions } from '@/helpers/permissions';

import VModal from 'vue-js-modal'

Vue.config.productionTip = false

Object.keys(filters).forEach(key => {
	Vue.filter(key, filters[key])
})
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDLh2OBm6862NYZWA2F-CETF2qd9jzusG8',
		libraries: 'places'
  },
  installComponents: true
})

Vue.use(VModal)

Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: 'mdi'
})

Vue.prototype.$checkPermissions = checkPermissions

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app')
