import {endpoints} from './endpoints'
import request from '../request'

export function getConversations (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getConversations,
			method: 'get',
			params: data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getConversation (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getConversation,
			method: 'get',
			params: data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function newMessage (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.newMessage,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function editConversationUser (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.editConversationUser,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function sendScheduleEmail (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.sendScheduleEmail,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}
