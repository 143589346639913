import moment from 'moment'
import {GENDER_MALE} from '@/consts'

const filters = {
	toThousandFilter(num) {
		const number = (+num || 0).toString().split('.')[0]
		const decimal = (+num || 0).toString().split('.')[1]
		let formatted = number.replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, '.'))
		if(decimal) formatted += ',' + decimal
		return formatted
	},
	toDateTime(date){
		return moment(date).format('DD-MM-YYYY | HH:mm')
	},
	toDate(date, fromFormat = null, format = 'DD-MM-YYYY'){
		if(fromFormat){
			return moment(date, fromFormat).format(format)
		}
		return moment(date).format(format)
	},
	toHour(date){
		return moment(date).format('HH:mm')
	},
	oneDecimalsNumber(number){
		return Math.round(number * 10) / 10
	},
	gender(gender){
		return gender == GENDER_MALE ? 'Masculino':'Femenino'
	},
	genderIcon(gender){
		return gender == GENDER_MALE ? 'mdi-gender-male':'mdi-gender-female'
	},
	addTargetsToLinksMessages(message) {
		const container = document.createElement('div');
		container.innerHTML = message;
		const links = container.getElementsByTagName('a');
		for (let i = 0; i < links.length; i++) {
			links[i].setAttribute('target', '_blank');
		}
		return container.innerHTML;
	}
}

export default filters