<template>
  <div class="text-center">
    <v-dialog
      v-model="$store.state.vt.agendaClosureModal"
      max-width="600"
      scrollable
    >
      <v-card>
        <v-card-text class="pa-0">
          <div class="py-4 px-8">
            <div class="d-flex flex-row align-center justify-space-between">
              <div class="d-flex flex-row align-center">
                <v-icon color="white" small class="info-icon mr-2">mdi-file-document-plus-outline</v-icon>
                <p class="mb-0 font-weight-bold text-primary title">Cerrar Visita Técnica</p>
              </div>
              <v-btn class="mx-2" fab dark small color="#F4516C" @click="closeModal">
                <v-icon dark>mdi-close</v-icon>
              </v-btn>
            </div>
            <v-form ref="form" v-model="validform" class="mt-6">
							<v-row class="align-center">
								<v-col cols="6">
									<v-menu
										ref="menu"
										v-model="menuDatePicker"
										:close-on-content-click="false"
										:return-value.sync="closeAgenda.date"
										transition="scale-transition"
										offset-y
										min-width="auto"
										eager
									>
										<template v-slot:activator="{ on, attrs }">
											<v-text-field
												v-model="readOnlyDate"
												label="Fecha"
												prepend-icon="mdi-calendar"
												readonly
												hide-details
												v-bind="attrs"
												v-on="on"
											></v-text-field>
										</template>
										<v-date-picker
											v-model="closeAgenda.date"
											no-title
											scrollable
											:first-day-of-week="1"
											locale="es-cl"
											:events="holidays"
											:picker-date.sync="pickerDate"
											event-color="red lighten-1"
											@input="(date) => $refs.menu.save(date)"
											class="pb-4"
										/>
									</v-menu>
								</v-col>
								<v-col cols="6">
									<v-menu
										ref="menutime"
										v-model="menuTimePicker"
										:close-on-content-click="false"
										:return-value.sync="closeAgenda.time"
										transition="scale-transition"
										offset-y
										max-width="290px"
										min-width="290px"
									>
										<template v-slot:activator="{ on, attrs }">
											<v-text-field
												v-model="closeAgenda.time"
												label="Hora"
												prepend-icon="mdi-clock-time-four-outline"
												readonly
												hide-details
												v-bind="attrs"
												v-on="on"
											></v-text-field>
										</template>
										<v-time-picker
											v-if="menuTimePicker"
											v-model="closeAgenda.time"
											full-width
											format="24hr"
											@click:minute="$refs.menutime.save(closeAgenda.time)"
										></v-time-picker>
									</v-menu>
								</v-col>
								<v-col cols="12">
									<v-text-field
										v-model="closeAgenda.closure_report"
										label="Comentario Cierre (Opcional)"
										flat
										hide-details
										dense
										outlined
										maxlength="250"
									></v-text-field>
                </v-col>
							</v-row>

              <v-row align="center">
                <v-col cols="12">
                  <div class="d-flex flex-row justify-end">
                    <v-btn
                      class="mx-2 my-1"
                      color="white"
                      raised
                      @click="closeModal"
                    >
                      <p class="mb-0 text-primary">Cancelar</p>
                    </v-btn>
										<v-btn
											depressed
											color="#524D4D"
											small
											outlined
											:loading="loading"
											class="ml-2"
											style="height: 40px"
											@click="closeScheduleVt()"
										>
											<v-icon small>mdi-lock-check-outline</v-icon>
											<p class="mb-0 ml-2 caption">Cerrar Visita Técnica</p>
										</v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment"
import {closeSchedule, getHolidays} from '@/helpers/api/schedule'

export default {
  name: 'AgendaClosure',
  data: () => ({
    validform: true,
		loadingMce: true,
		menuDatePicker: false,
		menuTimePicker: false,
		pickerDate: null,
		currentYear: 2024,
		currentYearHolidays: null,
		closeAgenda: {
			agenda_id: null,
			closed: null,
			date: moment(new Date()).format('YYYY-MM-DD'),
			time: moment(new Date()).format('HH:mm'),
			closure_report: ''
		},
    requiredRule: [
      v => !!v || 'Campo requerido'
    ],
		loading: false,
    emailRules: [
      v => !!v || 'Email es obligatorio',
      v => /.+@.+\..+/.test(v) || 'Email debe ser válido',
    ]
  }),
  computed: {
		readOnlyDate(){
			return moment(this.closeAgenda.date).format('DD-MM-YYYY')
		},
		holidays(){
			return this.currentYearHolidays || this.$store.state.general.holidayDays
		},
		selectedCompany(){
			return this.$store.state.companies.selectedCompany
		}
	},
	watch: {
		currentYear(newValue, oldValue){
			if(newValue != oldValue){
				this.updateHolidays()
			}
		},
		pickerDate(newValue){
			if(newValue){
				this.currentYear = moment(newValue).format('YYYY')
			}
		}
	},
  mounted(){
		this.updateHolidays()
		this.$nextTick(() => {
			this.loadingMce = false
			this.closeAgenda.agenda_id = this.$store.state.vt.agendaClosureModal
		});
  },
  methods: {
		async updateHolidays(){
			const holidays = await getHolidays({year: this.currentYear})
			if(holidays.code == 200){
				this.currentYearHolidays = holidays.events
			}
		},
		async closeScheduleVt(){
      if(this.$refs.form.validate()){
				const data = { ...this.closeAgenda, ...{closed: this.closeAgenda.date + ' ' + this.closeAgenda.time}}
				const report = await closeSchedule(data)
				this.$store.dispatch('crm/getConversation', this.$route.params.id)
				if(report.code == 200){
					const data = {
						message: 'Cierre realizado correctamente',
						title: 'Ok',
						created: new Date(),
						type: 'success',
						icon: 'mdi-check-circle',
						color: '#3EBFA3'
					}
					this.$store.dispatch('general/addMessage', data)
					this.closeModal()
				}
				else{
					const data = {
						message: 'Por favor intenta nuevamente.',
						title: 'Error',
						created: new Date(),
						type: 'danger',
						icon: 'mdi-close-octagon',
						color: '#F4516C',
					}
					this.$store.dispatch('general/addMessage', data)
				}
			}
		},
    closeModal(){
      this.$store.commit('vt/setAgendaClosureModal', false)
      this.closeAgenda = {
				agenda_id: null,
				closed: null,
				date: moment(new Date()).format('YYYY-MM-DD'),
				time: moment(new Date()).format('HH:mm'),
				closure_report: ''
			}
			this.loadingMce = true
      this.validform = true
      this.loadingData = false
    }
  }
};
</script>

<style scoped>
	.profile-avatar{
		cursor: pointer;
	}
  .info-container{
    color: #606060;
  }
  .text-primary{
    color: #524D4D;
  }
  .info-icon{
    background-color: #524D4D;
    border-radius: 25px;
    padding: 3px;
    width: 35px;
    height: 35px;
  }
  .info-icon.small-icon{
    width: 25px;
    height: 25px;
  }
  .info-img{
    width: 100%;
    max-height: 50px;
    object-fit: contain;
  }
  .adjuntos-container{
    background-color: #EFEFEF;
    max-height: 95px;
    overflow-y: scroll;
  }
  .interaction-container{
    width: 100%;
    background-color: #E5E9F2;
    border-radius: 5px;
    position: relative;
    padding: 12px;
  }
  .user-avatar.small{
    width: 25px;
    height: 25px;
  }
  .light-blue-text{color: #959595;}
  .interaction-container .icon-arrow{
    font-size: 80px;
    position: absolute;
    top: 20px;
    line-height: inherit;
  }
  .interaction-container.right .icon-arrow{
    left: -46px;
  }
  .interaction-container.left .icon-arrow{
    right: -46px;
  }
  /deep/ .custom-input-numbers .v-input__slot{padding:0!important;}
  /deep/ .custom-input-numbers .v-input__prepend-inner,
  /deep/ .custom-input-numbers .v-input__append-inner,
  /deep/ .custom-text-input .v-input__append-inner{margin-top:0!important;}
  /deep/ .custom-input-numbers input {text-align: center }
</style>