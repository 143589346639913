<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="$store.state.companies.companiesProjectTypes"
      :search="search"
      item-key="id"
      :loading="$store.state.companies.loadingCompaniesProjectTypes"
      loading-text="Cargando datos..."
    >
      <template v-slot:item.actions="{ item }">
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							fab
							dark
							x-small
							color="warning"
							class="mx-1"
							v-if="$checkPermissions(['edit_company_project_types'])"
							@click="editProjectType(item)"
							v-bind="attrs"
							v-on="on"
						>
							<v-icon small dark>mdi-pencil-outline</v-icon>
						</v-btn>
					</template>
					<span>Editar</span>
				</v-tooltip>
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							fab
							dark
							x-small
							color="error"
							class="mx-1"
							v-if="$checkPermissions(['delete_company_project_types'])"
							@click="deleteProjectType(item.id)"
							v-bind="attrs"
							v-on="on"
						>
							<v-icon small dark>mdi-delete-outline</v-icon>
						</v-btn>
					</template>
					<span>Eliminar</span>
				</v-tooltip>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import {deleteProjectType} from '@/helpers/api/company'
export default {
  data() {
    return {
      search: '',
      headers: [
        { text: 'Nombre', value: 'name' },
        { text: 'Descripción', value: 'description' },
        { text: 'Código', value: 'code' },
        { text: 'Acciones', value: 'actions', sortable: false },
      ],
    };
  },
  computed: {
		selectedCompany(){
			return this.$store.state.companies.selectedCompany
		}
  },
	watch: {
		selectedCompany(newValue){
			if(newValue){
				this.$store.dispatch('companies/getCompanyProjectTypes')
			}
		}
	},
	mounted(){
		if(this.selectedCompany){
			this.$store.dispatch('companies/getCompanyProjectTypes')
		}
	},
  methods: {
    addProjectType() {
			this.$store.commit('companies/setNewProjectTypeModalEditing', null)
			this.$store.commit('companies/setNewProjectTypeModal', true)
    },
    editProjectType(project_type) {
			const data = {
				project_type_id: project_type.id,
				name: project_type.name,
				description: project_type.description,
				code: project_type.code
			}
			this.$store.commit('companies/setNewProjectTypeModalEditing', data)
			this.$store.commit('companies/setNewProjectTypeModal', true)
    },
    async deleteProjectType(role_id) {
      await deleteProjectType({role_id})
			this.$store.dispatch('companies/getCompanyProjectTypes')
    }
  },
};
</script>

<style scoped>
</style>