import { getScheduleGeo } from "@/helpers/api/schedule"

const DashboardStore = {
  namespaced: true,
  state: {
		loadingScheduleGeo: false,
		scheduleGeo: []
  },
  mutations: {
    setScheduleGeo(state, scheduleGeo) {
      state.scheduleGeo = scheduleGeo
    },
    setLoadingScheduleGeo(state, loadingScheduleGeo) {
			state.loadingScheduleGeo = loadingScheduleGeo
    }
  },
  actions: {
    async getScheduleGeo({ commit }) {
      commit('setLoadingScheduleGeo', true)
      const geo = await getScheduleGeo({})
      if (geo.code === 200) {
        commit('setScheduleGeo', geo.schedule)
      }
      commit('setLoadingScheduleGeo', false)
    }
  }
}

export default DashboardStore
