import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import {getStates, getCities, getPolicyAcceptance} from '@/helpers/api/general'

const messagesStore = {
  namespaced: true,
  state: {
    messagesToMessageCenter: [],
		headerHeight: 0,
		viewerHeight: window.innerHeight,
		loadingStates: false,
		states: [],
		loadingCities: false,
		cities: [],
		holidayDays: [],
		policiesLists: [],
		loadingPolicies: false,
		userGeo: null
  },
  mutations: {
		setHeaderHeight(state, height) {
			state.headerHeight = height
		},
    setMessagesToMessageCenter(state, message) {
      let messages = [...state.messagesToMessageCenter]
      messages.push(message)
      state.messagesToMessageCenter = messages
    },
    removeMessagesToMessageCenter(state, message_uuid) {
      let messages = [...state.messagesToMessageCenter]

      const founded = messages.findIndex((item) => {
        return item.uuid === message_uuid
      })
      if (founded !== -1) {
        messages.splice(founded, 1)
        state.messagesToMessageCenter = messages
      }
    },
		setLoadingStates(state, value){
			state.loadingStates = value
		},
		setStates(state, value){
			state.states = value
		},
		setLoadingCities(state, value){
			state.loadingCities = value
		},
		setCities(state, value){
			state.cities = value
		},
		setLoadingPolicies(state, value){
			state.loadingPolicies = value
		},
		setPolicies(state, value){
			state.policiesLists = value
		},
		setHolidayDays(state, value){
			state.holidayDays = value
		},
		setUserGeo(state, value){
			state.userGeo = value
		},
  },
  getters: {
    availableHeight: state => state.viewerHeight - state.headerHeight
  },
  actions: {
    addMessage({ commit }, message) {
      message.uuid = uuidv4()
      commit('setMessagesToMessageCenter', message)
      setTimeout(() => {
        commit('removeMessagesToMessageCenter', message.uuid)
      }, message.close ? message.close : 5000)
    },
    async getStates({ commit }) {
      commit('setLoadingStates', true)
      const data = {
        country_id: 44
      }
      const states = await getStates(data)
      if (states.code === 200) {
        commit('setStates', states.states)
      }
      commit('setLoadingStates', false)
    },
    async getCities({ commit }, state_id) {
      commit('setLoadingCities', true)
      const cities = await getCities({state_id})
      if (cities.code === 200) {
        commit('setCities', cities.cities)
      }
      commit('setLoadingCities', false)
    },
    async getPolicies({ commit }, params) {
      commit('setLoadingPolicies', true)
      const policies = await getPolicyAcceptance(params)
      if (policies.code === 200) {
				console.log('policies.policies: ', policies.policies)
        commit('setPolicies', policies.policies)
      }
      commit('setLoadingPolicies', false)
    },
    async getHolidayDays({ commit }) {
			const url = `https://api.boostr.cl/holidays.json`;
			const response = await fetch(url);
			if (response.ok) {
				const data = await response.json();
				const holidayDays = data.data.map(f => moment(f.date).format('YYYY-MM-DD'))

				commit('setHolidayDays', holidayDays)
			}
    }
  }
}

export default messagesStore
