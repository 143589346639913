const endpoints = {
	getVTReportTemplates: '/schedule/getVTReportTemplates',
	createVTReportTemplate: '/schedule/createVTReportTemplate',
	updateVTReportTemplate: '/schedule/updateVTReportTemplate',
	deleteVTReportTemplate: '/schedule/deleteVTReportTemplate',
	getVtPolygons: '/schedule/getVtPolygons',
	createVtPolygonMap: '/schedule/createVtPolygonMap',
	updateVtPolygonMap: '/schedule/updateVtPolygonMap',
	updatePriorityVtPolygonMap: '/schedule/updatePriorityVtPolygonMap',
	deleteVtPolygonMap: '/schedule/deleteVtPolygonMap'
}
export {endpoints}