<template>
	<v-list class="menu-item py-0" dense>
		<MenuItem
			v-for="(item, index) in menuItems"
			:key="index"
			:item="item"
			/>
	</v-list>
</template>

<script>
import MenuItem from './Item'

export default {
	name: 'MenuIndex',
	components: { MenuItem },
	computed: {
		menuItems(){
			const userPermissions = this.$store.state.user.companyPermissions
			if(!userPermissions){
				return []
			}
			const items = this.$store.state.menu.sidebarMenu.map((item) => {
				if (!item.requiredPermissions || item.requiredPermissions.every(permission => userPermissions[permission] === true)) {
					const filteredSubMenu = item.subMenu ? item.subMenu.filter(subItem => {
						return !subItem.requiredPermissions || subItem.requiredPermissions.every(permission => userPermissions[permission] === true);
					}) : [];
					
					return {
						...item,
						subMenu: filteredSubMenu
					};
				}
				return null;
			}).filter(item => item !== null);

			return items;
		}
	},
	data: () => ({})
};
</script>