import { getVTReportTemplates, getVtPolygons } from "@/helpers/api/vts"

const VTStore = {
  namespaced: true,
  state: {
		vtReportTemplates: [],
		loadingVtReportTemplates: false,
		vtPolygons: [],
		loadingVtPolygons: false,
		newReportTemplateModal: false,
		newReportTemplateEditing: null,
		agendaClosureModal: false
  },
  mutations: {
    setVtReportTemplates(state, vtReportTemplates) {
      state.vtReportTemplates = vtReportTemplates
    },
    setloadingVtReportTemplates(state, loadingVtReportTemplates) {
      state.loadingVtReportTemplates = loadingVtReportTemplates
    },
    setVtPolygons(state, vtPolygons) {
      state.vtPolygons = vtPolygons
    },
    setloadingVtPolygons(state, loadingVtPolygons) {
      state.loadingVtPolygons = loadingVtPolygons
    },
    setNewReportTemplateEditing(state, newReportTemplateEditing) {
      state.newReportTemplateEditing = newReportTemplateEditing
    },
    setNewReportTemplateModal(state, newReportTemplateModal) {
      state.newReportTemplateModal = newReportTemplateModal
    },
    setAgendaClosureModal(state, agendaClosureModal) {
      state.agendaClosureModal = agendaClosureModal
    }
  },
  actions: {
    async getVtReportTemplates({ commit }) {
			commit('setloadingVtReportTemplates', true)
      const templates = await getVTReportTemplates()
      if (templates.code === 200) {
        commit('setVtReportTemplates', templates.templates)
      }
			commit('setloadingVtReportTemplates', false)
    },
    async getVtPolygons({ commit }) {
			commit('setloadingVtPolygons', true)
      const polygons = await getVtPolygons({})
      if (polygons.code === 200) {
        commit('setVtPolygons', polygons.polygons)
      }
			commit('setloadingVtPolygons', false)
    },
  }
}

export default VTStore
