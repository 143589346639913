import {endpoints} from './endpoints'
import request from '../request'

export function getClientCRM (params) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getClientCRM,
			method: 'get',
			params
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getClientPreProjects (params) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getClientPreProjects,
			method: 'get',
			params
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}