import { getUserSchedules } from "@/helpers/api/schedule"

const ScheduleStore = {
  namespaced: true,
  state: {
		loadingSchedules: true,
		schedules: [],
		userSchedules: [],
		newScheduleModal: false,
		newScheduleEditing: null,
		forceAgendaMode: false,
		userGoogleCalendar: null
  },
  mutations: {
    setSchedules(state, schedules) {
      state.schedules = schedules
    },
    setUserSchedules(state, userSchedules) {
      state.userSchedules = userSchedules
    },
    setLoadingSchedules(state, loadingSchedules) {
      state.loadingSchedules = loadingSchedules
    },
    setNewScheduleEditing(state, newScheduleEditing) {
      state.newScheduleEditing = newScheduleEditing
    },
    setNewScheduleModal(state, newScheduleModal) {
      state.newScheduleModal = newScheduleModal
    },
		setUserGoogleCalendar(state, userGoogleCalendar){
			state.userGoogleCalendar = userGoogleCalendar
		},
		setForceAgendaMode(state, forceAgendaMode){
			state.forceAgendaMode = forceAgendaMode
		}
  },
  actions: {
    async getUserSchedule({ commit }, search) {
			commit('setLoadingSchedules', true)
			const g_calendar = localStorage.getItem('g_calendar')
			let calendar = false
			if(g_calendar){
				calendar = JSON.parse(g_calendar)
			}
      // const schedule = await getUserSchedule()
      // if (schedule.code === 200) {
      //   commit('setSchedules', schedule.schedules)
      // }
      const schedules = await getUserSchedules({search, calendar})
      if (schedules.code === 200) {
        commit('setUserSchedules', schedules.schedules)
				commit('setForceAgendaMode', !!search)
				commit('setUserGoogleCalendar', schedules.calendar)
      }
			commit('setLoadingSchedules', false)
    },
  }
}

export default ScheduleStore
