<template>
	<div class="chatbox-container" v-show="$store.state.chatbot.showChatBox">
		<div>
			<v-icon class="close-button-chat" @click="$store.commit('chatbot/setShowChatBox', !$store.state.chatbot.showChatBox)">mdi-close</v-icon>
			<div class="messages-container" ref="messagesContainer" v-if="conversationMessages.length > 0">
				<div
					v-for="(message, index) in conversationMessages"
					:key="index"
					class="single-message" :class="message.role == 1 ? 'assistant':''"
					v-html="$options.filters.addTargetsToLinksMessages(message.message)">
				</div>
			</div>
			<div class="messages-container nomessages" v-else>
				Escribe un mensaje para iniciar la conversación
			</div>
		</div>
		<div class="user-input-container">
			<v-progress-linear indeterminate v-if="$store.state.chatbot.loadingConversation"></v-progress-linear>
			<div class="user-input-inner-container">
				<v-autocomplete
					ref="messageInputB"
					v-if="conversation?.message_data?.tags.includes('ADDRESS_REQUEST')"
					v-model="new_message"
					:items="[...items, ...[new_message]]"
					name="address"
					:search-input.sync="search"
					prepend-inner-icon="mdi-map-marker"
					label="Dirección"
					placeholder="Dirección"
					autocomplete="off"
					outlined
					dense
					hide-details
					:disabled="$store.state.chatbot.loadingConversation"
					:loading="loading"
					:filter="d => d"
					color="secondary"
					item-color="secondary"
					return-object
					@change="change"
					@keydown.enter="sendMessage"
				>
					<template v-slot:append-outer>
						<v-btn icon @click="sendMessage">
							<v-icon>mdi-send</v-icon>
						</v-btn>
					</template>
				</v-autocomplete>
				<v-text-field
					ref="messageInput"
					v-else
					v-model="new_message"
					placeholder="Escribe un mensaje"
					hide-details
					dense
					outlined
					required
					:disabled="$store.state.chatbot.loadingConversation"
					@keydown.enter="sendMessage"
				>
					<template v-slot:append-outer>
						<v-btn icon @click="sendMessage">
							<v-icon>mdi-send</v-icon>
						</v-btn>
					</template>
				</v-text-field>
			</div>
		</div>
	</div>
</template>

<script>
import { gmapApi } from 'vue2-google-maps'
export default {
	name: 'ChatBox',
	computed: {
		google: gmapApi,
		conversationMessages(){
			return this.$store.state.chatbot.conversation_messages
		},
		conversation(){
			return this.$store.state.chatbot.conversation
		}
	},
	watch: {
		conversationMessages(){
			setTimeout(() => {
				this.scrollToBottom()
			}, 300);
			this.$nextTick(() => {
				if (this.$refs.messageInput) {
					this.$refs.messageInput.focus();
				}
				if (this.$refs.messageInputB) {
					this.$refs.messageInputB.focus();
				}
			});
		},
    search(newSearch) {
      if (newSearch) {
        this.googleSearch(newSearch);
      } else {
        this.items = []; // Limpiar los resultados si no hay búsqueda
      }
    },
	},
  data() {
    return {
			new_message: '',
			adding_message: null,
			items: [],
			search: '',
			autocompleteService: null,
			loading: false,
    };
  },
	mounted(){
		this.scrollToBottom()
		this.$nextTick(() => {
			this.$gmapApiPromiseLazy().then(() => {
				this.autocompleteService = new this.google.maps.places.AutocompleteService()
			})
		})
	},
  methods: {
    scrollToBottom() {
      const container = this.$refs.messagesContainer;
			if(container){
				container.scrollTop = container.scrollHeight;
			}
    },
		appendNewMessageTemp(message){
			const add_message = {
				message,
				role: 2
			}
			const messages = this.$store.state.chatbot.conversation_messages
			messages.push(add_message)
			this.$store.commit('chatbot/setConversationMessages', messages)
		},
		sendMessage(){
			if(typeof this.new_message === 'string' && this.new_message.trim() !== ''){
				this.appendNewMessageTemp(this.new_message)
				this.$store.dispatch('chatbot/newMessage', this.new_message)
				this.new_message = ''
			}
		},
    googleSearch(query) {
      const options = {
        input: query,
				componentRestrictions: { country: 'CL' }
      };
      this.autocompleteService.getPlacePredictions(options, (predictions, status) => {
        if (status === this.google.maps.places.PlacesServiceStatus.OK) {
          this.items = predictions.map(prediction => ({
            text: prediction.description,
            value: prediction 
          }));
        } else {
          console.error('Error fetching predictions: ', status);
          this.items = [];
        }
      });
    },
    change(value) {
      this.new_message = value.text;
    },
  },
};
</script>

<style scoped>
	.close-button-chat{
		position: absolute;
		top: 5px;
		left: 5px;
		z-index: 999999;
	}
	.v-text-field {
		align-items: center;
	}
	.chatbox-container{
		display: flex;
		flex-direction: column;
		background: white;
		margin-bottom: 8px;
		width: 400px;
		min-height: 200px;
		border: 1px solid rgb(205, 205, 205);
		border-radius: 10px;
		box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.2);
	}
	.messages-container{
		display: flex;
		flex-direction: column;
		padding: 10px;
		flex: 1;
		border-radius: 10px;
		height: 400px;
		background-color: #f3f3f0;
		overflow-y: scroll;
		text-transform: none;
	}
	.user-input-container{
		flex: 0;
	}
	.user-input-inner-container{
		padding: 10px;
	}
	.single-message{
		background-color: rgb(217, 239, 217);
		border: 1px solid rgb(197, 232, 197);
		padding: 4px 8px;
		max-width: 80%;
		border-radius: 10px;
		font-size: 14px;
		margin-bottom: 8px;
		text-transform: none;
	}
	.single-message.assistant{
		background-color: rgb(213, 211, 211);
		border: 1px solid rgb(193, 191, 191);
		align-self: flex-end;
	}
	.nomessages{
		text-align: center;
		font-size: 14px;
		margin-top: 30px;
	}
</style>
<style>
	.chatbox-container .single-message p{
		margin-bottom: 0;
	}
</style>