import {endpoints} from './endpoints'
import request from '../request'

export function getVTReportTemplates (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getVTReportTemplates,
			method: 'get',
			params: data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function createVTReportTemplate (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.createVTReportTemplate,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function updateVTReportTemplate (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.updateVTReportTemplate,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function deleteVTReportTemplate (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.deleteVTReportTemplate,
			method: 'delete',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getVtPolygons (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getVtPolygons,
			method: 'get',
			params: data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function createVtPolygonMap (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.createVtPolygonMap,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function updateVtPolygonMap (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.updateVtPolygonMap,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function updatePriorityVtPolygonMap (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.updatePriorityVtPolygonMap,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function deleteVtPolygonMap (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.deleteVtPolygonMap,
			method: 'delete',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}