import { getConcepts, getAdminCompanies, getRoles, getProjectTypes, getUserCompanyStatus, updateUserCompanyStatus } from "@/helpers/api/company"

const companyStore = {
  namespaced: true,
  state: {
    selectedCompany: null,
		concepts: [],
		loadingConcepts: false,
		newCompanyConceptModal: false,
		newCompanyConceptModalEditing: null,
		newCompanyModal: false,
		newCompanyModalFirst: false,
		newCompanyModalEditing: null,
		companiesList: [],
		loadingCompaniesList: false,
		loadingRoles: false,
		roles: [],
		newRoleModalEditing: null,
		newRoleModal: false,
		newProjectTypeModalEditing: null,
		newProjectTypeModal: false,
		companiesProjectTypes: [],
		loadingCompaniesProjectTypes: true,
		userCompanyConnectedStatus: null,
		loadingUserCompanyConnectedStatus: true
  },
  mutations: {
    setSelectedCompany(state, company) {
      state.selectedCompany = company
    },
    setLoadingConcepts(state, loadingConcepts) {
      state.loadingConcepts = loadingConcepts
    },
    setNewRoleModalEditing(state, newRoleModalEditing) {
      state.newRoleModalEditing = newRoleModalEditing
    },
    setNewRoleModal(state, newRoleModal) {
      state.newRoleModal = newRoleModal
    },
    setConcepts(state, concepts) {
      state.concepts = concepts
    },
    setLoadingRoles(state, loadingRoles) {
      state.loadingRoles = loadingRoles
    },
    setRoles(state, roles) {
      state.roles = roles
    },
    setNewCompanyConceptModal(state, newCompanyConceptModal) {
      state.newCompanyConceptModal = newCompanyConceptModal
    },
    setNewCompanyConceptModalEditing(state, newCompanyConceptModalEditing) {
      state.newCompanyConceptModalEditing = newCompanyConceptModalEditing
    },
    setNewCompanyModal(state, newCompanyModal) {
      state.newCompanyModal = newCompanyModal
    },
    setNewCompanyModalFirst(state, newCompanyModalFirst) {
      state.newCompanyModalFirst = newCompanyModalFirst
    },
    setNewCompanyModalEditing(state, newCompanyModalEditing) {
      state.newCompanyModalEditing = newCompanyModalEditing
    },
    setCompaniesList(state, companiesList) {
      state.companiesList = companiesList
    },
    setLoadingCompaniesList(state, loadingCompaniesList) {
      state.loadingCompaniesList = loadingCompaniesList
    },
    setCompaniesProjectTypes(state, companiesProjectTypes) {
      state.companiesProjectTypes = companiesProjectTypes
    },
    setLoadingCompaniesProjectTypes(state, loadingCompaniesProjectTypes) {
      state.loadingCompaniesProjectTypes = loadingCompaniesProjectTypes
    },
    setUserCompanyConnectedStatus(state, status) {
      state.userCompanyConnectedStatus = status
    },
    setLoadingUserCompanyConnectedStatus(state, status) {
      state.loadingUserCompanyConnectedStatus = status
    },
    setNewProjectTypeModalEditing(state, newProjectTypeModalEditing) {
      state.newProjectTypeModalEditing = newProjectTypeModalEditing
    },
    setNewProjectTypeModal(state, newProjectTypeModal) {
      state.newProjectTypeModal = newProjectTypeModal
    }
  },
  actions: {
    async getConcepts({ commit }) {
      commit('setLoadingConcepts', true)
      const data = {
        start: 0,
        limit: 30
      }
      const concepts = await getConcepts(data)
      if (concepts.code === 200) {
        commit('setConcepts', concepts.concepts)
      }
      commit('setLoadingConcepts', false)
    },
    async getProjectTypes({ commit }) {
      commit('setLoadingConcepts', true)
      const data = {
        start: 0,
        limit: 30
      }
      const concepts = await getConcepts(data)
      if (concepts.code === 200) {
        commit('setConcepts', concepts.concepts)
      }
      commit('setLoadingConcepts', false)
    },
    async getRoles({ commit }, data) {
      commit('setLoadingRoles', true)
      const dataSend = {
        ...data
      }
      const concepts = await getRoles(dataSend)
      if (concepts.code === 200) {
        commit('setRoles', concepts.roles)
      }
      commit('setLoadingRoles', false)
    },
    async getCompaniesList({ commit }) {
      commit('setLoadingCompaniesList', true)
      const data = {
        start: 0,
        limit: 30
      }
      const companies = await getAdminCompanies(data)
      if (companies.code === 200) {
        commit('setCompaniesList', companies.companies)
      }
      commit('setLoadingCompaniesList', false)
    },
    async getCompanyProjectTypes({ commit }) {
      commit('setLoadingCompaniesProjectTypes', true)
      const data = {
        start: 0,
        limit: 30
      }
      const project_types = await getProjectTypes(data)
      if (project_types.code === 200) {
        commit('setCompaniesProjectTypes', project_types.project_types)
      }
      commit('setLoadingCompaniesProjectTypes', false)
    },
    async getUserCompanyConnectedStatus({ commit }, data) {
      commit('setLoadingUserCompanyConnectedStatus', true)
      const status = await getUserCompanyStatus(data)
      if (status.code === 200) {
        commit('setUserCompanyConnectedStatus', status.status)
      }
      commit('setLoadingUserCompanyConnectedStatus', false)
    },
    async updateUserCompanyConnectedStatus({ commit }, data) {
      commit('setLoadingUserCompanyConnectedStatus', true)
      const status = await updateUserCompanyStatus(data)
      if (status.code === 200) {
        commit('setUserCompanyConnectedStatus', status.status)
      }
      commit('setLoadingUserCompanyConnectedStatus', false)
    }
  }
}

export default companyStore
