<template>
	<v-container fluid class="crm-index">
		<v-data-table
			ref="table"
			:headers="headers"
			:items="$store.state.preprojects.preProjects"
			:search="search"
			item-key="id"
			:loading="$store.state.preprojects.loadingPreProjects"
			loading-text="Cargando datos..."
			class="crm-table"
			:class="$vuetify.breakpoint.mobile ? '':'resizable'"
			disable-sort
      :hide-default-header="!$vuetify.breakpoint.mobile"
		>
			<template v-slot:header="{ props }" v-if="!$vuetify.breakpoint.mobile">
				<thead class="v-data-table-header">
					<tr>
						<th role="columnheader" scope="col" aria-label="#" class="header-cell"
							style="max-height: 30px; height: 25px;"
							:style="{ width: head.width ? head.width + 'px' : 'auto' }"
							:class="head.align == 'center' ? 'text-center':''"
							v-for="(head,index) in props.headers" :key="index"
							@mouseup="saveColumnWidth(index)"
						>
							<span>{{ head.text.toUpperCase() }}</span>
						</th>
					</tr>
				</thead>
			</template>
			<template v-slot:item.client="{ item }">
				<div><b>{{ item.client && item.client.profile?.name ? item.client.profile?.name:'No identificado'}}</b></div>
				<div v-if="item.client.profile">{{ item.client.profile ? item.client.profile?.email:'No identificado'}}</div>
			</template>
			<template v-slot:item.address="{ item }">
				<div><b>{{ item.info ? item.info.address:'Sin registro'}}</b></div>
			</template>
			<template v-slot:item.project_name="{ item }">
				<div><b>{{ item.name }}</b></div>
			</template>
			<template v-slot:item.entity="{ }">
				<div><b>Municipalidad</b></div>
			</template>
			<template v-slot:item.project_type="{ item }">
				<!-- <div><small>Tipo Proyecto:</small></div> -->
				<div>
					<b class="mr-2">{{ item.info?.project_type?.code }}</b>
				</div>
			</template>
			<template v-slot:item.project_detail="{ item }">
				<div><b>{{ item.info?.details ? item.info.details:'Sin registro'}}</b></div>
			</template>
			<template v-slot:item.created_at="{ item }">
				<!-- <div><small>Tipo Proyecto:</small></div> -->
				<div><b>{{ item.created_at | toDateTime }}</b></div>
			</template>
			<template v-slot:item.actions="{ item }">
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							class="mx-1"
							icon
							dark
							x-small
							color="primary"
							@click="toPreProjectDetail(item)"
							v-bind="attrs"
							v-on="on"
						>
							<v-icon small dark>mdi-eye</v-icon>
						</v-btn>
					</template>
					<span>Ver Detalle</span>
				</v-tooltip>
				<!-- <v-tooltip bottom v-if="$checkPermissions(['delete_crm'])">
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							icon
							dark
							x-small
							color="error"
							class="mx-1"
							@click="removeCrm(item.id)"
							v-bind="attrs"
							v-on="on"
						>
							<v-icon small dark>mdi-delete-outline</v-icon>
						</v-btn>
					</template>
					<span>Eliminar</span>
				</v-tooltip> -->
			</template>
		</v-data-table>
	</v-container>
</template>

<script>
import moment from 'moment'
import {sendScheduleEmail} from '@/helpers/api/conversations'
import {deleteCrmEntry, editCrmProjectType} from '@/helpers/api/crm'
import {resizableGrid} from '@/helpers/resize-table'

export default {
	data() {
		return {
			search: '',
			columnWidths: {}
		};
	},
	computed: {
		headers(){
			let headers = [
				{ text: '#', value: 'id', width: '55px', align: 'center', sortable: false },
				{ text: 'Nombre', align: 'start', value: 'client', sortable: false },
				{ text: 'Dirección', align: 'start', value: 'address', sortable: false },
				{ text: 'Pre Proyecto', align: 'start', value: 'project_name', sortable: false },
				{ text: 'Municipalidad', align: 'start', value: 'entity', sortable: false },
				{ text: 'Tipo Pre Proyecto', value: 'project_type', sortable: false },
				{ text: 'Especificación', value: 'project_detail', sortable: false },
				{ text: 'Fecha Creación', align: 'start', value: 'created_at', sortable: false },
				{ text: 'Acciones', align: 'start', value: 'actions', sortable: false },
			]
			return headers
			// if(!this.$vuetify.breakpoint.mobile){
			// 	const storedWidths = JSON.parse(localStorage.getItem('columnWidths')) || {};
			// 	headers = headers.map((header) => {
			// 		return {
			// 			...header,
			// 			width: storedWidths[header.value] || 'auto'
			// 		}
			// 	})
			// }
			// return headers.filter((header) => {
			// 	return this.$store.state.crm.listingColumns[header.value]
			// })
		},
		filteredUsers() {
			return this.$store.state.users.usersList.filter(user => {
				return (
					user.name.toLowerCase().includes(this.search.toLowerCase()) ||
					user.email.toLowerCase().includes(this.search.toLowerCase())
				);
			});
		},
		selectedCompany(){
			return this.$store.state.companies.selectedCompany
		},
		loadingPreProjects(){
			return this.$store.state.preprojects.loadingPreProjects
		}
	},
	watch: {
		selectedCompany(newValue){
			if(newValue){
				this.$store.dispatch('preprojects/getPreProjects')
			}
		},
		loadingPreProjects(newValue){
			if(!newValue){
				this.$nextTick(() => {
					resizableGrid(this.$refs.table.$el)
				})
			}
		}
	},
	mounted(){
		if(this.selectedCompany){
			this.$store.dispatch('preprojects/getPreProjects')
		}
		const savedWidths = JSON.parse(localStorage.getItem('columnWidths'));
		if (savedWidths) {
			this.columnWidths = savedWidths;
		}
	},
	methods: {
		toPreProjectsStart(crm){
			const preproject_info = {
				crm_id: crm.id,
				client_id: crm.user.user_id
			}
			localStorage.setItem('new_preproject_info', JSON.stringify(preproject_info));
			this.$store.commit('preprojects/setNewPreProjectModal', true)
		},
		saveColumnWidth(index) {
			const header_value = this.headers[index].value
			const headerCell = this.$refs.table.$el.querySelectorAll('.header-cell')[index];
			const newWidth = headerCell.offsetWidth;
			this.$set(this.columnWidths, header_value, newWidth);
			localStorage.setItem('columnWidths', JSON.stringify(this.columnWidths));
		},
		async changeProjectType(conversation_id, project_type_id){
			await editCrmProjectType({conversation_id, project_type_id})
			this.$store.dispatch('crm/getConversations')
		},
		async removeCrm(crm_id){
			await deleteCrmEntry({crm_id})
			this.$store.dispatch('crm/getConversations')
		},
		async resendPaymentRequest(conversation){
			try {
				await sendScheduleEmail({schedule_id: conversation.schedule.id})
				const data = {
					message: 'El correo con la solicitud de pago ha sido enviado al usuario.',
					title: 'Correo enviado correctamente',
					created: new Date(),
					type: 'success',
					icon: 'mdi-check-circle',
					color: '#3EBFA3'
				}
				this.$store.dispatch('general/addMessage', data)
				this.$store.dispatch('crm/getConversations')
			} catch {
				const data = {
					message: 'Por favor intenta nuevamente.',
					title: 'Error al enviar el correo',
					created: new Date(),
					type: 'danger',
					icon: 'mdi-close-octagon',
					color: '#F4516C',
				}
				this.$store.dispatch('general/addMessage', data)
			}
		},
		sentEmailDiff(conversation){
			if(conversation.schedule && conversation.schedule.request_email.length > 0){
				const current = moment()
				const startTime = conversation.schedule.request_email[0].created_at
				var duration = moment.duration(current.diff(startTime))
				var hours = duration.asHours()
				return hours
			}
			else if(conversation.schedule.request_email.length == 0){
				return -1
			}
			return null
		},
		addUser() {
			this.$store.commit('users/setNewUserModal', true)
		},
		toPreProjectDetail(preproject) {
			this.$router.push({ name: 'PreProject', params: { id: preproject.id } })
		},
		editUser(user) {
			alert(`Editar usuario: ${user.name}`);
		},
		deleteUser(user) {
			const index = this.users.indexOf(user);
			if (index > -1) {
				this.users.splice(index, 1);
			}
		},
	},
};
</script>

<style>
	.crm-index .message-container{
		margin-bottom: 0;
		font-size: 12px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.crm-index .message-container p{
		margin-bottom: 0;
		font-size: 12px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.crm-table table tr td{
		font-size: 11px!important;
	}
	.crm-table.resizable .v-data-table__wrapper th,
	.crm-table.resizable .v-data-table__wrapper td {
		border-right: 1px solid #cacaca;
	}
</style>
<style scoped>
	.menu-bg-white{background: white;}
</style>