import {endpoints} from './endpoints'
import request from '../request'

export function getUserNotifications (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getUserNotifications,
			method: 'get',
			params: data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function markAsReaded (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.markAsReaded,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}