<template>
	<v-container fluid style="background-color: #dcdbdb;" fill-height class="d-flex flex-column justify-center">
		<v-snackbar
			v-model="showMessage"
			vertical
			bottom
			center
			:color="messageColor"
		>
			<p class="mb-0 text-center">{{message}}</p>
		</v-snackbar>
		<img :src="require('../../assets/logoestudio.png')" style="max-width: 150px; width: 100%;"/>
		<div class="login-form-container d-flex justify-center mt-8">
			<v-form
				ref="form"
				v-model="valid"
				lazy-validation
				class="d-flex flex-column flex-grow-1"
				style="max-width: 400px;width: 100%;"
			>
				<v-text-field
					v-model="password"
					autocomplete="off"
					:rules="passwordRules"
					label="Contraseña"
					color="#959595"
					required
					:append-icon="passType ? 'mdi-eye-off' : 'mdi-eye'"
					@click:append="() => (passType = !passType)"
					:type="passType ? 'password' : 'text'"
				></v-text-field>
				<v-text-field
					v-model="password_confirmation"
					autocomplete="off"
					name="passconfirm"
					:rules="passwordConfirmRules"
					label="Confirmar Contraseña"
					color="#959595"
					hide-details
					required
					validate-on-blur
					:append-icon="passType ? 'mdi-eye-off' : 'mdi-eye'"
					@click:append="() => (passType = !passType)"
					:type="passType ? 'password' : 'text'"
				></v-text-field>
				<div class="mt-1 mb-3">
					<div class="d-flex align-center">
						<v-icon x-small :color="passwordValidation(1) ? 'green':'red'">{{passwordValidation(1) ? 'mdi-check':'mdi-close'}}</v-icon>
						<p class="mr-2 mb-0 caption" style="font-size: 10px!important;">Al menos 8 caracteres</p>
					</div>
					<div class="d-flex align-center">
						<v-icon x-small :color="passwordValidation(2) ? 'green':'red'">{{passwordValidation(2) ? 'mdi-check':'mdi-close'}}</v-icon>
						<p class="mr-2 mb-0 caption" style="font-size: 10px!important;">Al menos 1 minúscula</p>
					</div>
					<div class="d-flex align-center">
						<v-icon x-small :color="passwordValidation(3) ? 'green':'red'">{{passwordValidation(3) ? 'mdi-check':'mdi-close'}}</v-icon>
						<p class="mr-2 mb-0 caption" style="font-size: 10px!important;">Al menos 1 mayúscula</p>
					</div>
					<div class="d-flex align-center">
						<v-icon x-small :color="passwordValidation(4) ? 'green':'red'">{{passwordValidation(4) ? 'mdi-check':'mdi-close'}}</v-icon>
						<p class="mr-2 mb-0 caption" style="font-size: 10px!important;">Al menos 1 caracter especial de #$@!%*?&</p>
					</div>
				</div>
				<v-btn
					:disabled="!valid"
					color="#524D4D"
					:dark="valid"
					class="mt-2"
					:loading="loading"
					@click="resetPass"
				>
					Reestablecer Contraseña
				</v-btn>
				<v-btn
					color="#524D4D"
					class="mt-4"
					small
					text
					@click="$router.push({name: 'Login'})"
				>
					Ir a Login
				</v-btn>
			</v-form>
		</div>
	</v-container>
</template>

<script>
import {resetPass, checkToken} from '@/helpers/api/user'

export default {
	name: 'ResetPass',
	computed: {
		passwordConfirmRules() {
			return [
				(this.password_confirmation !== '') || 'Confirmación de contraseña es requerida',
				(this.password_confirmation === this.password) || 'Las contraseñas no coinciden'
			]
		}
	},
	data: () => ({
		valid: true,
		showMessage: false,
		message: '',
		messageColor: 'error',
		password: '',
		password_confirmation: '',
		passwordRules: [
			v => !!v || 'Contraseña es requerida',
			v => /^(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(v) || 'No cumple con las validaciones de seguridad'
		],
		loading: false,
		passType: true,
		token: null
	}),
	mounted(){
		this.token = this.$route.params.token
		this.checkTokenValidity(this.token)
		this.$store.commit('user/logoutUser')
	},
	methods: {
		passwordValidation(type = null) {
			const password = this.password;

			if (type === 1) {
				// Retornar true si la contraseña tiene menos de 8 caracteres
				return password.length >= 8;
			} 
			else if (type === 2) {
				// Retornar true si la contraseña no tiene al menos una letra minúscula
				return /[a-z]/.test(password);
			} 
			else if (type === 3) {
				// Retornar true si la contraseña no tiene al menos una letra mayúscula
				return /[A-Z]/.test(password);
			} 
			else if (type === 4) {
				// Retornar true si la contraseña no tiene al menos un carácter especial
				return /[#$@!%*?&]/.test(password);
			}
			
			// Validación completa (si no se pasa un tipo específico)
			return !/^(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(password)
		},
		validate () {
			this.$refs.form.validate()
		},
		reset () {
			this.$refs.form.reset()
		},
		resetValidation () {
			this.$refs.form.resetValidation()
		},
		async checkTokenValidity(token){
			const tokenResp = await checkToken({token})
			if(tokenResp.code !== 200){
				this.message = 'El token de validación no se encuentra válido, si aún lo necesitas, por favor solicita nuevamente reestablecer tu contraseña.'
				this.messageColor = 'warning'
				this.showMessage = true
				setTimeout(() => {
					this.$router.push({name: 'Login'})
				}, 6000)
			}
		},
		async resetPass () {
			this.loading = true
			const reset = await resetPass({
				token: this.token,
				password: this.password,
				password_confirmation: this.password_confirmation
			})
			this.loading = false
			
			if(reset.code === 200){
				this.message = 'Se ha reestablecido la contraseña por favor ingresa. Serás redirigido al login.'
				this.messageColor = 'success'
				this.showMessage = true
				setTimeout(() => {
					this.$router.push({name: 'Login'})
				}, 4000)
			}
			else{
				this.message = 'Lo sentimos, ha ocurrido un error inesperado. Por favor inténtalo nuevamente.'
				this.messageColor = 'warning'
				this.showMessage = true
			}
		},
	},
};
</script>
<style scoped>
	.login-form-container{
		width: 100%;
	}	
</style>
