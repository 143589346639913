<template>
	<v-container fluid style="background-color: #959595;" fill-height class="d-flex flex-column justify-center">
		<v-snackbar
			v-model="showMessage"
			vertical
			bottom
			center
			:color="messageColor"
		>
			<p class="mb-0">{{message}}</p>
		</v-snackbar>
		<v-card light min-width="700" max-height="95vh" class="py-2 px-0" style="overflow-y: scroll;">
		<div class="login-form-container d-flex justify-space-between align-center" style="width: 100%">
			<div style="flex:1;width: 220px;text-align: center">
				<img :src="require('../../assets/logoestudio.png')" style="max-width: 150px; width: 100%;"/>
				<p class="mb-0 caption px-2 mt-4">PLATAFORMA DIGITAL COLABORATIVA DE COORDINACIÓN DE PROYECTOS Y SERVICIOS DE ARQUITECTURA, DISEÑO, GESTIÓN INMOBILIARIA Y CONSTRUCCIÓN</p>
			</div>
			<v-form
				ref="form"
				v-model="valid"
				lazy-validation
				class="d-flex flex-column flex-grow-1 mt-8 pr-4"
				style="max-width: 400px;width: 100%;"
			>
				<v-text-field
					v-model="name"
					:rules="requiredRules"
					label="Nombre"
					:disabled="loadingLogin"
					required
					color="#959595"
				></v-text-field>
				<v-text-field
					v-model="lastname"
					:rules="requiredRules"
					:disabled="loadingLogin"
					label="Apellido"
					required
					color="#959595"
				></v-text-field>
				<v-text-field
					v-model="email"
					:rules="emailRules"
					:disabled="loadingLogin"
					label="Email"
					required
					color="#959595"
					autocomplete="username"
				></v-text-field>
				<v-text-field
					v-model="userPassword"
					:rules="passwordRules"
					:disabled="loadingLogin"
					label="Contraseña"
					color="#959595"
					required
					hide-details
					:append-icon="passType ? 'mdi-eye-off' : 'mdi-eye'"
					@click:append="() => (passType = !passType)"
					:type="passType ? 'password' : 'text'"
					autocomplete="new-password"
				></v-text-field>
				<div class="mt-1 mb-3">
					<div class="d-flex align-center">
						<v-icon x-small :color="passwordValidation(1) ? 'green':'red'">{{passwordValidation(1) ? 'mdi-check':'mdi-close'}}</v-icon>
						<p class="mr-2 mb-0 caption" style="font-size: 10px!important;">Al menos 8 caracteres</p>
					</div>
					<div class="d-flex align-center">
						<v-icon x-small :color="passwordValidation(2) ? 'green':'red'">{{passwordValidation(2) ? 'mdi-check':'mdi-close'}}</v-icon>
						<p class="mr-2 mb-0 caption" style="font-size: 10px!important;">Al menos 1 minúscula</p>
					</div>
					<div class="d-flex align-center">
						<v-icon x-small :color="passwordValidation(3) ? 'green':'red'">{{passwordValidation(3) ? 'mdi-check':'mdi-close'}}</v-icon>
						<p class="mr-2 mb-0 caption" style="font-size: 10px!important;">Al menos 1 mayúscula</p>
					</div>
					<div class="d-flex align-center">
						<v-icon x-small :color="passwordValidation(4) ? 'green':'red'">{{passwordValidation(4) ? 'mdi-check':'mdi-close'}}</v-icon>
						<p class="mr-2 mb-0 caption" style="font-size: 10px!important;">Al menos 1 caracter especial de #$@!%*?&</p>
					</div>
				</div>
				<div class="d-flex flex-column justify-center align-center mb-5 policy-check">
					<div>
						<div class="d-flex align-center">
							<v-checkbox class="my-0 py-0" v-model="acceptance" color="#959595" hide-details dense></v-checkbox>
							<a class="caption" href="https://panel.toledoarquitectos.cl/policies" target="_blank">He leído y acepto las Políticas de Privacidad</a>
						</div>
						<div class="d-flex align-center">
							<v-checkbox class="my-0 py-0" v-model="acceptance" color="#959595" hide-details dense></v-checkbox>
							<a class="caption" href="https://panel.toledoarquitectos.cl/policies" target="_blank">He leído y acepto los Términos y Condiciones</a>
						</div>
					</div>
				</div>
				<v-btn
					color="#524D4D"
					dark
					:loading="loadingLogin"
					@click="registerUser"
				>
					Registrarme
				</v-btn>
				<v-btn
					depressed
					color="#DD4F40"
					dark
					block
					class="mt-4"
					:loading="loadingLogin"
					@click="toSocialLogin('google')"
				>
					<v-icon>mdi-google</v-icon>
					<p class="mb-0 ml-2 subtitle-2"
					v-show="!$store.state.menu.sidebarMini">Registro con Google</p>
				</v-btn>
				<v-divider class="my-5"></v-divider>
				<v-btn
					color="#524D4D"
					small
					text
					@click="$router.push({name: 'Login'})"
				>
					Ya tengo Cuenta
				</v-btn>
			</v-form>
		</div>
	</v-card>
	</v-container>
</template>

<script>
import {register, login, loginWithGoogleToken} from '@/helpers/api/user'

export default {
	name: 'Register',
	data: () => ({
		valid: true,
		showMessage: false,
		messageColor: 'error',
		message: '',
		name: '',
		lastname: '',
		email: '',
		emailRules: [
			v => !!v || 'Email es obligatorio',
			v => /.+@.+\..+/.test(v) || 'Email debe ser válido',
		],
		userPassword: '',
		requiredRules: [
			v => !!v || 'Campo requerido'
		],
		passwordRules: [
			v => !!v || 'Contraseña es requerida',
			v => /^(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(v) || 'No cumple con las validaciones de seguridad'
		],
		passType: true,
		loadingLogin: false
	}),
	mounted(){
		this.$store.commit('user/setIsAuthenticated', false)
		this.socialLoginFromToken()
	},
	methods: {
		passwordValidation(type = null) {
			const password = this.userPassword;

			if (type === 1) {
				// Retornar true si la contraseña tiene menos de 8 caracteres
				return password.length >= 8;
			} 
			else if (type === 2) {
				// Retornar true si la contraseña no tiene al menos una letra minúscula
				return /[a-z]/.test(password);
			} 
			else if (type === 3) {
				// Retornar true si la contraseña no tiene al menos una letra mayúscula
				return /[A-Z]/.test(password);
			} 
			else if (type === 4) {
				// Retornar true si la contraseña no tiene al menos un carácter especial
				return /[#$@!%*?&]/.test(password);
			}
			
			// Validación completa (si no se pasa un tipo específico)
			return !/^(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(password)
		},
		validate () {
			this.$refs.form.validate()
		},
		reset () {
			this.$refs.form.reset()
		},
		resetValidation () {
			this.$refs.form.resetValidation()
		},
		toSocialLogin(provider = 'google'){
			this.loadingLogin = true
			window.location.href = `https://apiarq.insomnia.cl/social-oauth?provider=${provider}`
		},
		socialLoginFromToken(){
			const access_token = this.$route.query.social_access_token;
			if (access_token) {
				this.loadingLogin = true
				loginWithGoogleToken(access_token)
					.then(() => {
						this.loadingLogin = false
						this.$store.commit('user/setIsAuthenticated', true)
						this.$router.push({name: 'Dashboard'})
					})
					.catch((error) => {
						this.loadingLogin = false
						this.message = 'Lo sentimos, ha ocurrido un error inesperado. Por favor inténtalo nuevamente.'
						if(error.response && error.response.status == 401){
							this.message = 'Email o contraseña incorrectos. Por favor verifica los datos ingresados.'
						}
						this.messageColor = 'warning'
						this.showMessage = true
					});
			}
		},
		registerUser () {
			if(!this.$refs.form.validate()){ return null }
			this.loadingLogin = true
			const data = {
				name: this.name,
				lastname: this.lastname,
				email: this.email,
				password: this.userPassword,
				password_confirmation: this.userPassword
			}
			register(data)
				.then(async () => {
					const data = {
						message: 'La cuenta ha sido creada correctamente, serás dirigido al panel principal.',
						title: 'Cuenta creada con éxito',
						created: new Date(),
            type: 'success',
            icon: 'mdi-check-circle',
            color: '#3EBFA3'
					}
					this.$store.dispatch('general/addMessage', data)
					this.loginUser()
				})
				.catch((e) => {
					console.log(e)
					this.loadingLogin = false
					this.message = 'Lo sentimos, ha ocurrido un error inesperado. Por favor inténtalo nuevamente.'
					if(e.data.length > 0){
						this.message = e.data[0]
					}
					this.messageColor = 'warning'
					this.showMessage = true
				})
		},
		loginUser () {
			this.loadingLogin = true
			login(this.email, this.userPassword)
				.then(async () => {
					this.loadingLogin = false
					this.$store.commit('user/setIsAuthenticated', true)
					this.$router.push({name: 'Dashboard'})
				})
				.catch((error) => {
					this.loadingLogin = false
					this.message = 'Lo sentimos, ha ocurrido un error inesperado. Por favor inténtalo nuevamente.'
					if(error.response && error.response.status == 401){
						this.message = 'Email o contraseña incorrectos. Por favor verifica los datos ingresados.'
					}
					this.message = 'Lo sentimos, ha ocurrido un error inesperado. Por favor inténtalo nuevamente.'
					if(error.data.length > 0){
						this.message = error.data[0]
					}
					this.messageColor = 'warning'
					this.showMessage = true
				})
		},
	},
};
</script>
<style>
.policy-check label{
	font-size: 12px;
}
</style>
