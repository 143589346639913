import { render, staticRenderFns } from "./ClientDetail.vue?vue&type=template&id=0ee8da16&scoped=true&"
import script from "./ClientDetail.vue?vue&type=script&lang=js&"
export * from "./ClientDetail.vue?vue&type=script&lang=js&"
import style0 from "./ClientDetail.vue?vue&type=style&index=0&id=0ee8da16&prod&scoped=true&lang=css&"
import style1 from "./ClientDetail.vue?vue&type=style&index=1&id=0ee8da16&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ee8da16",
  null
  
)

export default component.exports